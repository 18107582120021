import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import CheckoutButton from "./CheckoutButton";
import Menu from "./Menu";

export default function MultiCheckout({ languages }) {
  const [currentLanguage, setCurrentLanguage] = useState(
    languages[0]?.language
  );

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
  };

  return (
    <>
      {languages ? (
        <Box>
          <Menu
            languages={languages}
            changeLanguage={changeLanguage}
            currentLanguage={currentLanguage}
          ></Menu>

          <CheckoutButton language={currentLanguage} />
        </Box>
      ) : null}
    </>
  );
}

MultiCheckout.propTypes = {
  languages: PropTypes.array.isRequired,
};
