import { extendTheme } from "@chakra-ui/react";
import { fonts } from "../../theme/fonts";

// const customComponents = {
const theme = {
  semanticTokens: {
    colors: {
      // primary: {
      //   default: "black",
      //   _dark: "white",
      // },
      bg: {
        default: "white",
        _dark: "gray.800",
      },
      codeTitlesBg: {
        default: "#FCFDFF",
        _dark: "#060F2F",
      },
      bgCode: {
        default: "gray.50",
        _dark: "gray.700",
      },
      bgCodeBorder: {
        default: "gray.200",
        _dark: "gray.500",
      },
      codeBlockBorder: {
        default: "gray.300",
        _dark: "gray.500",
      },

      bgCodeBorderSelected: {
        default: "pink.500",
        _dark: "pink.400",
      },
      primary: {
        default: "blue.500",
        _dark: "blue.600",
      },
      bg_primary: {
        default: "blue.50",
        _dark: "blue.400",
      },
      warn: {
        default: "yellow.200",
        _dark: "yellow.600",
      },
      bg_warn: {
        default: "yellow.50",
        _dark: "yellow.500",
      },
      error: {
        default: "red.500",
        _dark: "red.600",
      },
      bg_error: {
        default: "red.100",
        _dark: "red.300",
      },
    },
  },
  fonts,
};

export default extendTheme(theme);
// export default extendTheme({...theme, ...customComponents});
