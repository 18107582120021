import PropTypes from "prop-types";
import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Link,
  Text,
} from "@chakra-ui/react";

export const PopUp = (props) => {
  const text = props.text;
  const definition = props.definition;

  if (props.url) {
    var url = props.url;
  }

  if (props.plural) {
    var plural = props.plural;
  }

  if (props.pluralWithEs) {
    var pluralWithEs = props.pluralWithEs;
  }

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Link>
          <Text as="i">
            {text}
            {plural && "s"}
            {pluralWithEs && "es"}
          </Text>
        </Link>
      </PopoverTrigger>
      <PopoverContent role={"tooltip"}>
        <PopoverBody>
          {definition}{" "}
          {url && (
            <Link
              href={url}
              color={"primary"}
              fontWeight={"semibold"}
              target="_blank"
            >
              here.
            </Link>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

PopUp.propTypes = {
  text: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
  url: PropTypes.string,
  plural: PropTypes.bool,
  pluralWithEs: PropTypes.bool,
};
