import React, { useEffect, useCallback } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { colors } from "../theme/colors";
import Installation from "./Installation";
import Field from "./Field";
import { Link } from "@chakra-ui/react";

export default function StepsExplanation({
  steps,
  changeActiveStep,
  activeStep,
  handleNumberFieldOnChange,
  handleSelectFieldOnChange,
  params,
}) {
  const scrollToActiveStep = useCallback(
    (id) => {
      const currentStep = document.getElementById(`step${id}`);

      if (currentStep) {
        scrollIntoView(currentStep, { behavior: "smooth" });
      }
    },
    [activeStep]
  );

  const handleScroll = useCallback(
    (event) => {
      const halfway = event.currentTarget.innerHeight / 2;

      for (let i = steps.length - 1; i >= 0; i--) {
        const currentStep = steps[i];
        const currentStepDiv = document.getElementById(
          `step${steps[i].id}`
        );
        if (currentStepDiv) {
          const { top } = currentStepDiv.getBoundingClientRect();
          if (top <= halfway) {
            if (currentStep !== activeStep) {
              changeActiveStep(currentStep);
            }
            break;
          }
        }
      }
    },
    [activeStep]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeStep]);

  console.log(steps);

  return (
    <Box>
      {steps &&
        steps.map((step) => {
          return (
            <Box
              borderBottomWidth={1}
              key={step.id}
              id={`step${step.id}`}
            >
              <Box
                p={5}
                _hover={
                  activeStep?.id !== step.id &&
                  !step.doesNotGetSelected && {
                    cursor: "pointer",
                    borderLeftWidth: "4px",
                    borderColor: "bgCodeBorder",
                    borderBottomWidth: "0",
                    paddingLeft: 4,
                  }
                }
                sx={
                  activeStep?.id === step.id && {
                    borderLeftWidth: 4,
                    backgroundColor: "bgCode",
                    borderColor: colors.pink.dark,
                    cursor: "pointer",
                    pl: 4,
                  }
                }
                onClick={() => {
                  if (!step.doesNotGetSelected) {
                    scrollToActiveStep(step.id);
                  }
                }}
              >
                <Heading size="sm">{step.heading}</Heading>

                {step.explanation &&
                  step.explanation.map((part) => {
                    return (
                      <Text key={step.id} fontSize="md" mb={3}>
                        {part}
                      </Text>
                    );
                  })}
                {step.code && <Installation step={step} />}
                {step.input &&
                  step.input.map((field) => {
                    return (
                      <Field
                        field={field}
                        handleNumberFieldOnChange={
                          handleNumberFieldOnChange
                        }
                        handleSelectFieldOnChange={
                          handleSelectFieldOnChange
                        }
                        params={params}
                      />
                    );
                  })}
                {step.href && (
                  <Link
                    href={step.href.link}
                    color={"primary"}
                    fontWeight={"semibold"}
                    target={"_blank"}
                  >
                    {step.href.text}
                  </Link>
                )}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}

StepsExplanation.propTypes = {
  steps: PropTypes.array.isRequired,
  changeActiveStep: PropTypes.func.isRequired,
  activeStep: PropTypes.object,
  handleNumberFieldOnChange: PropTypes.func,
  handleSelectFieldOnChange: PropTypes.func,
  params: PropTypes.object,
};
