import React, { useState } from "react";
import { Box, Button, Text, useClipboard } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { colors } from "../theme/colors";
import { motion, AnimatePresence } from "framer-motion";
import { FiCheck, FiClipboard } from "react-icons/fi";

export default function Installation({ step }) {
  const [showCopy, setShowCopy] = useState(false);
  const { onCopy, hasCopied } = useClipboard("");
  const [tab, setTab] = useState(0);
  const [prevTab, setPrevTab] = useState(0);

  return (
    <Box key={step.id} overflowX="hidden">
      <Box
        borderBottomWidth={1}
        borderColor={"bgCodeBorder"}
        pb={2}
        //pl={4}
      >
        {step.code.map((part) => {
          return (
            <Text
              key={part.id}
              fontSize={"sm"}
              d={"inline"}
              mr={5}
              pb={3}
              pr={5}
              pl={5}
              fontWeight={"semibold"}
              sx={
                tab === part.id && {
                  borderBottomWidth: 2,
                  borderColor: colors.pink.dark,
                }
              }
              onClick={() => {
                let tempPrevTab = tab;
                setPrevTab(tempPrevTab);
                setTab(part.id);
              }}
            >
              {part.name}
            </Text>
          );
        })}
      </Box>
      {step.code.map((part) => {
        return (
          tab === part.id && (
            <AnimatePresence>
              <motion.div
                initial={{
                  x: prevTab === tab ? 0 : prevTab < tab ? 300 : -300,
                  opacity: 0,
                }}
                animate={{ x: 0, opacity: 1 }}
                exit={{
                  x: prevTab < tab ? -300 : 300,
                  opacity: 0,
                }}
                transition={{ x: { duration: 0.5 } }}
              >
                <Box key={part.id}>
                  <Text mt={3} mb={1} fontSize={"md"}>
                    {part.text}
                  </Text>
                  <Box
                    borderColor={"gray.200"}
                    borderWidth={1}
                    borderRadius={5}
                    p={2}
                    d={"flex"}
                    pos={"relative"}
                    onMouseEnter={() => setShowCopy(true)}
                    onMouseLeave={() => setShowCopy(false)}
                  >
                    <Box fontFamily={"mono"} fontSize="13">
                      $ {part.script}
                    </Box>
                    {showCopy && (
                      <Button
                        size="xs"
                        //colorScheme={"pink"}
                        backgroundColor={colors.pink.dark}
                        _hover={{
                          backgroundColor: "codeBlockBorder",
                        }}
                        onClick={() => {
                          onCopy();
                          //setCopiedItemId(step.code.indexOf(part));
                          navigator.clipboard.writeText(part.script);
                        }}
                        leftIcon={
                          hasCopied ? <FiCheck /> : <FiClipboard />
                        }
                        pos={"absolute"}
                        right={1}
                      >
                        {hasCopied ? "Copied!" : "Copy"}
                      </Button>
                    )}
                  </Box>
                </Box>
              </motion.div>
            </AnimatePresence>
          )
        );
      })}
    </Box>
  );
}

Installation.propTypes = {
  step: PropTypes.object.isRequired,
};
