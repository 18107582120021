import { colors } from "../theme/colors";

const { midnight, orange, grey, pink, teal, indigo, yellow } = colors;

export function usePrismTheme() {
  return {
    plain: {
      color: "currentColor",
      backgroundColor: midnight.darker,
    },
    styles: [
      {
        types: ["important", "bold"],
        style: {
          fontWeight: "bold",
        },
      },
      {
        types: ["italic"],
        style: {
          fontStyle: "italic",
        },
      },
      {
        types: ["entity"],
        style: {
          cursor: "help",
        },
      },
      {
        types: ["comment", "prolog", "doctype", "cdata"],
        style: {
          color: orange["light"],
          fontWeight: "bold",
        },
      },
      {
        types: ["punctuation"],
        style: {
          color: grey["light"],
        },
      },
      {
        types: [
          "property",
          "tag",
          "boolean",
          "number",
          "constant",
          "symbol",
          "deleted",
          "class-name",
          "function",
        ],
        style: {
          color: pink["light"],
        },
      },
      {
        types: [
          "selector",
          "attr-name",
          "string",
          "char",
          "builtin",
          "inserted",
        ],
        style: {
          color: teal["light"],
        },
      },
      {
        types: ["atrule", "attr-value", "keyword"],
        style: {
          color: "inherit",
          background: "transparent",
        },
      },
      {
        types: ["atrule", "attr-value", "keyword"],
        style: {
          color: indigo["light"],
        },
      },
      {
        types: ["regex", "important", "variable"],
        style: {
          color: yellow["light"],
        },
      },
    ],
  };
}
