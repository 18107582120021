import React from "react";
import PropTypes from "prop-types";
import {
  NumberInput,
  FormControl,
  FormLabel,
  NumberInputField,
  Input,
  Select,
} from "@chakra-ui/react";

export default function Field({
  field,
  handleNumberFieldOnChange,
  handleSelectFieldOnChange,
  params,
}) {
  const fieldType = field.fieldType;

  switch (fieldType) {
    case "number-input":
      return (
        <NumberField
          field={field}
          handleNumberFieldOnChange={handleNumberFieldOnChange}
          params={params}
        />
      );
    case "select":
      return (
        <SelectField
          field={field}
          handleSelectFieldOnChange={handleSelectFieldOnChange}
          params={params}
        />
      );
    case "text":
      return <TextField field={field} />;
    default:
      return TextField;
  }
}

Field.propTypes = {
  fieldType: PropTypes.string,
  handleNumberFieldOnChange: PropTypes.func.isRequired,
  handleSelectFieldOnChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

function NumberField({ field, handleNumberFieldOnChange, params }) {
  return (
    <FormControl onChange={handleNumberFieldOnChange}>
      <FormLabel>{field.name}</FormLabel>
      <NumberInput max={field.max} min={field.min} value={params.amount}>
        <NumberInputField placeholder={field.placeholder} />
      </NumberInput>
    </FormControl>
  );
}

function SelectField({ field, handleSelectFieldOnChange, params }) {
  return (
    <FormControl onChange={handleSelectFieldOnChange}>
      <FormLabel>{field.name}</FormLabel>
      <Select placeholder={field.placeholder} value={params.currency}>
        {field.options &&
          field.options.map((option) => {
            return <option>{option}</option>;
          })}
      </Select>
    </FormControl>
  );
}

function TextField({ field }) {
  return (
    <FormControl isRequired>
      <FormLabel>{field.name}</FormLabel>
      <Input placeholder={field.placeholder} />
    </FormControl>
  );
}
