import DocsetButton from './DocsetButton';
import DocsetGroup from './DocsetGroup';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import {FiGrid} from 'react-icons/fi';
import { 
  SiReact, 
  SiNodedotjs,
  SiShopify,
  SiWordpress,
  SiMagento
} from 'react-icons/si';

const CustomIcon = ({icon}) => <Box fill="current" boxSize="1em" as={icon} />;

CustomIcon.propTypes = {
  icon: PropTypes.elementType.isRequired
};

export const DOCSET_ICONS = {
  react: <SiReact />,
  node: <SiNodedotjs />,
  shopify: <SiShopify />,
  wordpress: <SiWordpress />,
  magento: <SiMagento />
};

export function DocsetMenu({docset, ...props}) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  return (
    <>
      <ButtonGroup isAttached {...props}>
        <Button rightIcon={<FiGrid />} onClick={onOpen} colorScheme="blue">
          {docset}
        </Button>
      </ButtonGroup>
      <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={[4, 6, 8]}>
          <ModalCloseButton />
          <Stack spacing="4" p="6">
            <DocsetGroup title="Get started">
              <DocsetButton leftIcon={<FiGrid />} to="/">
                Safepay Basics
              </DocsetButton>
            </DocsetGroup>
            <DocsetGroup title="SDKs">
              <DocsetButton leftIcon={DOCSET_ICONS.react} to="/integrations/sdks/react">
                React
              </DocsetButton>
              <DocsetButton leftIcon={DOCSET_ICONS.react} to="/integrations/sdks/react-native">
                React Native
              </DocsetButton>
              <DocsetButton leftIcon={DOCSET_ICONS.node} to="/integrations/sdks/node-js">
                Node JS
              </DocsetButton>
            </DocsetGroup>
            <DocsetGroup title="Plugins">
              <DocsetButton leftIcon={DOCSET_ICONS.shopify} to="/integrations/plugins/shopify">
                Shopify
              </DocsetButton>
              <DocsetButton leftIcon={DOCSET_ICONS.wordpress} to="/integrations/plugins/wordpress">
                Wordpress
              </DocsetButton>
              <DocsetButton leftIcon={DOCSET_ICONS.magento} to="/integrations/plugins/magento">
                Magento
              </DocsetButton>
            </DocsetGroup>
          </Stack>
        </ModalContent>
      </Modal>
    </>
  );
}

DocsetMenu.propTypes = {
  docset: PropTypes.string.isRequired
};
