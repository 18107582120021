import PropTypes from "prop-types";
import React from "react";
import { Link, List, ListItem, Text, Container } from "@chakra-ui/react";

export const SectionsNav = (props) => {
  const sections = props.sections;

  return (
    <>
      <Container m={0} p={0} w={"100%"}>
        <Text mt={8}>On this page</Text>
        <List overflow="auto" spacing="1" mt={1.5} mb={6}>
          {sections.map(({ value, href }, index) => {
            return (
              <ListItem key={index} lineHeight="normal">
                <Link
                  href={href}
                  color={"primary"}
                  fontWeight={"semibold"}
                  target={value === "Definitions" && "_blank"}
                >
                  {value}
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Container>
    </>
  );
};

SectionsNav.propTypes = {
  sections: PropTypes.array.isRequired,
};
