import PropTypes from "prop-types";
import React from "react";
import { Box, Text } from "@chakra-ui/react";

export const Info = (props) => {
  const text = props.text;

  return (
    <Box
      boxShadow="dark-lg"
      p="5"
      rounded="md"
      bg={"bg"}
      maxWidth="750px"
      mb={6}
    >
      {text[0] && <Text mb={text[1] && 3}>{text[0]}</Text>}
      {text[1] && <Text mb={3}>{text[1]}</Text>}
      {text[2] && <Text mb={3}>{text[2]}</Text>}
    </Box>
  );
};

Info.propTypes = {
  text: PropTypes.array.isRequired,
};
