import PropTypes from 'prop-types';
import React from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Image,
  Link,
  Tag,
  Text,
  useToken
} from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'
import {Link as GatsbyLink} from 'gatsby'
import { PrimaryLink } from './RelativeLink'

export function HeroSection({
  title = "Payments",
  description = "Build a web or mobile integration to accept payments online or in person.",
  path,
  tags = ["Online", "Checkout", "Cards"],
  image,
  cta = "Get started"
}) {
  const [grey] = useToken('colors', ['gray.50'])
  return (
    <Flex minH={'280px'} mb={'4'} sx={{ 'boxShadow': `inset 0 -1px ${grey}` }} align="center">
      <Box flexGrow={'1'} pr="5">
        <Heading as="h3" size="lg" mb="4">
          <span>{title}</span>
        </Heading>
        {description && <Text pb="4" pr="8">{description}</Text>}
        <Button
          as="a"
          href="https://getsafepay.com/dashboard/login"
          target={"_blank"}
          colorScheme="blue"
          rightIcon={<FiArrowRight />}
        >
          {cta}
        </Button>
        {tags.length && (
          <HStack mt="6" spacing="3">
            {tags.map((t, i) => (
              <Tag key={i}>{ t }</Tag>
            ))}
          </HStack>
        )}
      </Box>
      <Flex 
        alignSelf="flex-end" 
        flexGrow="1" 
        justify="flex-end"
        d={{
          base: 'none',
          sm: 'none',
          md: 'flex'
        }}
      >
        <Image 
          src="https://uploads-ssl.webflow.com/5f2d8cefa1f1ec3622a82394/5f327b58562cad4261ea45e8_connect%20(1)-p-800.png" 
          bottom="1px"
          h='100%'
          sx={{
            position: 'relative'
          }}
          w={{
            sm: 'none',
            md: '50vw',
            lg: '60vw',
            xl: '500px',
            base: '500px'
          }}
        />
      </Flex>
    </Flex>
  )
}

export const DocsetGrid = props => (
  <SimpleGrid spacing="4" minChildWidth="250px" {...props} />
);

export const CTA_LEARN = 'Learn about %s';

export function Docset({
  title,
  children,
  description,
  path,
  icon,
  cta = 'Explore %s docs'
}) {
  return (
    <Flex align="flex-start" p="6" rounded="md" borderWidth="1px">
      <Flex align="flex-start" direction="column" h="full">
        <Heading as="h3" size="lg" mb="4">
          <HStack spacing="3">
            {icon}
            <span>{title}</span>
          </HStack>
        </Heading>
        {description && <Text mb="4">{description}</Text>}
        <PrimaryLink mt="auto" fontWeight="semibold" as={GatsbyLink} to={path}>
          {cta.replace('%s', title)}
        </PrimaryLink>
      </Flex>
      {children}
    </Flex>
  );
}

Docset.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  path: PropTypes.string.isRequired,
  cta: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.node
};

export const CommunityButton = props => (
  <IconButton as={Link} fontSize="larger" isExternal {...props} />
);
