import {join, relative} from 'path';
import {withPrefix} from 'gatsby';
import { createContext } from 'react';
import {useColorModeValue} from '@chakra-ui/react';

export const PathContext = createContext()

export const isUrl = string => /^https?:\/\//.test(string);

export const isPathActive = (path, uri) =>
  !relative(
    // we need to prepend the path prefix to make this work properly in prod
    withPrefix(path),
    uri
  );
export const getFullPath = (path, basePath) => join('/', basePath, path);

export const flattenNavItems = items =>
  items.flatMap(item =>
    item.children ? [item, ...flattenNavItems(item.children)] : item
  );

export function useTagColors() {
  const bg = useColorModeValue('blue.50', 'blue.400');
  const textColor = useColorModeValue('blue.500', 'inherit');
  return [bg, textColor];
}