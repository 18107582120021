import React from "react";
import ReactDOM from "react-dom";

const isBrowser = () => typeof window !== "undefined";

// const module = typeof window !== 'undefined' ? require('@sfpy/checkout-components') : null;

let SafepayCheckoutComponent = () => <p>Not available in SSR</p>;

if (isBrowser()) {
  SafepayCheckoutComponent = window.safepay.Button.driver("react", {
    React: React,
    ReactDOM: ReactDOM,
  });
}

export default SafepayCheckoutComponent;
