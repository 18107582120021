import Highlight from "prism-react-renderer";
import Prism from "prismjs";
import PropTypes from "prop-types";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  chakra,
  useClipboard,
  UnorderedList,
  ListItem,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FiCheck, FiClipboard } from "react-icons/fi";
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";
import { colors } from "../theme/colors";
import { usePrismTheme } from "../utils/prism";

import SafepayCheckoutComponent from "./SafepayCheckoutComponent";

// these must be imported after Prism
import "prismjs/components/prism-markup";
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-graphql";
import "prismjs/components/prism-groovy";
import "prismjs/components/prism-java";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-kotlin";
import "prismjs/components/prism-ruby";
import "prismjs/components/prism-rust";
import "prismjs/components/prism-swift";
import "prismjs/components/prism-tsx";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-yaml";
import "prismjs/components/prism-php";
import "prismjs/components/prism-go";
import "prismjs/components/prism-python";
import Field from "./Field";

const CODE_BLOCK_SPACING = 4;
export const GA_EVENT_CATEGORY_CODE_BLOCK = "Code Block";

export const CodeBlockContext = createContext();
export const LineNumbersContext = createContext(true);

const isHighlightComment = (token, comment = "// highlight-line") =>
  token.types.includes("comment") && token.content === comment;

const isHighlightStart = (line, comment = "// highlight-start") =>
  line.some((token) => isHighlightComment(token, comment));

const isHighlightEnd = (line) =>
  isHighlightStart(line, "// highlight-end");

export default function IntegrationSteps({
  titles,
  activeTab,
  changeActiveTab,
  linesToHighlight,
  preview,
  fixedHeight,
}) {
  const code = activeTab?.code;
  const language = activeTab?.language;
  const defaultShowLineNumbers = useContext(LineNumbersContext);
  const showLineNumbers = defaultShowLineNumbers;

  //For animation
  const [showCopy, setShowCopy] = useState(false);
  const [copyBottom, setCopyBottom] = useState("0");
  const [copyOpacity, setCopyOpacity] = useState("0");

  const [fileContainerHeight, setFileContainerHeight] = useState(0);

  const [showPreview, setShowPreview] = useState(false);
  const [apiKey, setApiKey] = useState(null);

  const { onCopy, hasCopied } = useClipboard(code);
  const theme = usePrismTheme("gray");
  const highlightColor = colors.midnight.dark;
  const lineNumberColor = "gray.500";

  const languageMenu = useContext(CodeBlockContext);

  const scrollToHighlightedLines = (lineNumber) => {
    const currentLine = document.getElementById(
      `highlighted-line${lineNumber}`
    );

    if (currentLine) {
      currentLine.scrollIntoViewIfNeeded({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (showCopy) {
      setCopyBottom("38%");
      setCopyOpacity("1");
    } else {
      setCopyBottom("0");
      setCopyOpacity("0");
    }
  }, [showCopy]);

  useEffect(() => {
    setFileContainerHeight(
      document.getElementById("fileContainer").offsetHeight
    );
  }, []);

  return (
    <Box minW="65%" pos="relative">
      <Highlight
        Prism={Prism}
        theme={theme}
        code={code.trim()}
        language={language.replace(/^language-/, "")}
      >
        {({
          className,
          style,
          tokens,
          getLineProps,
          getTokenProps,
        }) => {
          // length of longest line number
          // ex. if there are 28 lines in the code block, lineNumberOffset = 2ch
          const lineNumberOffset =
            tokens.length.toString().length + "ch";

          // create an array of lines highlighted by "highlight-start" and
          // "highlight-end" comments
          const highlightRange = [];
          for (let i = 0; i < tokens.length; i++) {
            const line = tokens[i];
            if (isHighlightEnd(line)) {
              highlightRange.pop();
              break;
            }

            if (highlightRange.length || isHighlightStart(line)) {
              highlightRange.push(i + 1);
            }
          }

          return (
            <Box
              sx={{
                position: "-webkit-sticky",
                /* Safari */ position: "sticky",
                top: "130",
              }}
              onMouseEnter={() => setShowCopy(true)}
              onMouseLeave={() => setShowCopy(false)}
            >
              <Box id="fileContainer">
                <Box
                  // rounded="md"
                  borderTopRadius={5}
                  fontSize="md"
                  sx={{
                    position: "-webkit-sticky",
                    /* Safari */ position: "sticky",
                    top: "0",
                  }}
                  backgroundColor={colors.midnight.darker}
                  color={colors.white}
                  onMouseEnter={() => setShowCopy(false)}
                  onMouseLeave={() => setShowCopy(true)}
                >
                  {preview && (
                    <Box
                      borderBottomWidth="1px"
                      borderColor={"gray.600"}
                    >
                      <Box
                        cursor="pointer"
                        onClick={() => {
                          if (!showPreview) {
                            setShowPreview(true);
                          } else {
                            setShowPreview(false);
                          }
                        }}
                        d="flex"
                        alignItems="center"
                        p="2"
                        fontWeight={"semibold"}
                      >
                        {!showPreview ? (
                          <AiOutlineDown
                            style={{
                              marginRight: "3px",
                              fontWeight: "bold",
                            }}
                          />
                        ) : (
                          <AiOutlineUp
                            style={{ marginRight: "3px" }}
                          />
                        )}

                        <Box>Preview</Box>
                      </Box>

                      {showPreview && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexDirection={"column"}
                          mt="3"
                          mb="5"
                        >
                          <Box
                            w="100%"
                            display={"flex"}
                            textAlign={"center"}
                            justifyContent={"center"}
                          >
                            <FormControl
                              isRequired
                              w="40%"
                              onChange={(event) => {
                                setApiKey(event.target.value);
                              }}
                            >
                              <Input placeholder="Enter your API key here" />
                            </FormControl>
                          </Box>
                          {apiKey && (
                            <SafepayCheckoutComponent
                              env={"sandbox"}
                              client={{
                                sandbox: apiKey,
                              }}
                              style={{
                                mode: "light",
                                size: "small",
                                variant: "primary",
                              }}
                              orderId={"12344"}
                              payment={{
                                currency: "PKR",
                                amount: 1000.5,
                              }}
                              onPayment={(data) => {
                                // At this point your customer has approved the payment
                                // and you can show a success message or make an API request
                                // to your servers to add the data.

                                return fetch(
                                  `/api/orders/mark-paid`,
                                  {
                                    method: "post",
                                  }
                                );
                              }}
                              onCancel={() => {}}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  )}

                  <Box
                    px={CODE_BLOCK_SPACING}
                    borderBottomWidth="1px"
                    borderColor={"gray.600"}
                    // borderTopRadius="md"
                    pt={2}
                    pb={3}
                  >
                    <UnorderedList ml={0}>
                      {titles &&
                        titles.map((title) => {
                          return (
                            <ListItem
                              style={{
                                display: "inline",
                                marginRight: 8,
                              }}
                            >
                              <Button
                                backgroundColor={
                                  activeTab?.title === title
                                    ? "whiteAlpha.400"
                                    : "whiteAlpha.200"
                                }
                                _hover={{
                                  backgroundColor: "whiteAlpha.300",
                                }}
                                onClick={() =>
                                  changeActiveTab &&
                                  changeActiveTab(title)
                                }
                                fontSize={"xs"}
                                h={5}
                              >
                                {title}
                              </Button>
                            </ListItem>
                          );
                        })}
                    </UnorderedList>
                  </Box>
                </Box>
              </Box>
              <Box
                // rounded="md"
                maxH={fixedHeight && 550}
                h={
                  !fixedHeight &&
                  `calc(100vh - ${fileContainerHeight}px - 155px)`
                }
                style={style}
                lineHeight="base"
                overflow={"auto"}
                borderBottomRadius={5}
                id="codeBlockDiv"
              >
                <Flex
                  // overflow="auto"
                  fontSize={"sm"}
                  fontFamily="mono"
                  color="white"
                >
                  <chakra.pre
                    d="inline-block"
                    minW="full"
                    className={className}
                    py={CODE_BLOCK_SPACING}
                    fontFamily="inherit"
                    id="codeBlockContainer"
                    //h="100%"
                  >
                    {tokens
                      .filter(
                        (line) =>
                          !isHighlightStart(line) &&
                          !isHighlightEnd(line)
                      )
                      .map((line, i) => {
                        const shouldHighlight =
                          // if the line number exists in the metastring or highlight comment ranges
                          linesToHighlight
                            .concat(highlightRange)
                            .includes(i + 1) ||
                          // or if the line has a "highlight-line" comment in it
                          line.some(isHighlightComment);

                        if (shouldHighlight) {
                          scrollToHighlightedLines(i);
                        }
                        return (
                          <Flex
                            key={i}
                            px={CODE_BLOCK_SPACING}
                            // for line highlighting to go all the way across code block
                            minW="full"
                            w="fit-content"
                            bg={shouldHighlight && highlightColor}
                            id={`highlighted-line${i}`}
                            // sx={{
                            //   scrollBehavior: "smooth",
                            // }}
                          >
                            {showLineNumbers && (
                              <Box
                                aria-hidden="true"
                                userSelect="none"
                                // line number alignment used in VS Code
                                textAlign="right"
                                w={lineNumberOffset}
                                mr={CODE_BLOCK_SPACING}
                                color={lineNumberColor}
                              >
                                {i + 1}
                              </Box>
                            )}
                            <Box
                              {...getLineProps({
                                line,
                                key: i,
                              })}
                            >
                              <Box>
                                {line
                                  // filter out "highlight-line" comments
                                  .filter(
                                    (token) =>
                                      !isHighlightComment(token)
                                  )
                                  .map((token, key) => (
                                    <span
                                      key={key}
                                      {...getTokenProps({
                                        token,
                                        key,
                                      })}
                                    />
                                  ))}
                              </Box>
                            </Box>
                          </Flex>
                        );
                      })}
                  </chakra.pre>
                </Flex>
              </Box>

              <ButtonGroup
                pos="absolute"
                left="0"
                right="0"
                ml="auto"
                mr="auto"
                d="flex"
                w="100px"
                alignItems="center"
                justifyContent="center"
                colorScheme={"blue"}
                size="sm"
                sx={{
                  opacity: copyOpacity,
                  bottom: copyBottom,
                  transitionProperty: "opacity, bottom",
                  transitionTimingFunction: "ease-in-out",
                  transitionDuration: "0.3s",
                }}
              >
                <Button
                  leftIcon={hasCopied ? <FiCheck /> : <FiClipboard />}
                  onClick={() => {
                    onCopy();
                    window.gtag?.("event", "Copy", {
                      event_category: GA_EVENT_CATEGORY_CODE_BLOCK,
                    });
                  }}
                >
                  {hasCopied ? "Copied!" : "Copy"}
                </Button>
                {languageMenu}
              </ButtonGroup>
            </Box>
          );
        }}
      </Highlight>
    </Box>
  );
}

IntegrationSteps.propTypes = {
  titles: PropTypes.array.isRequired,
  activeTab: PropTypes.object.isRequired,
  changeActiveTab: PropTypes.func.isRequired,
  linesToHighlight: PropTypes.array.isRequired,
  preview: PropTypes.bool.isRequired,
  fixedHeight: PropTypes.bool,
};
