import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import Menu from "./Menu";
import IntegrationSteps from "./IntegrationSteps";
import { sigVerification } from "../utils/codeblocks";

export default function MultiCheckout({ languages }) {
  const [currentLanguage, setCurrentLanguage] = useState(
    languages[0]?.language
  );
  const [currentLanguageObj, setCurrentLanguageObj] = useState(
    languages[0]
  );

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    const temp = sigVerification.find(
      (element) => element.language === language
    );
    setCurrentLanguageObj(temp);
  };

  return (
    <Box>
      {currentLanguage && currentLanguageObj ? (
        <>
          <Menu
            languages={languages}
            changeLanguage={changeLanguage}
            currentLanguage={currentLanguage}
            currentLanguageObj={currentLanguageObj}
          ></Menu>
          <IntegrationSteps
            fixedHeight={currentLanguageObj.fixedHeight}
            titles={currentLanguageObj.titles}
            activeTab={currentLanguageObj.activeTab}
            linesToHighlight={currentLanguageObj.linesToHighlight}
          />
        </>
      ) : null}
    </Box>
  );
}

MultiCheckout.propTypes = {
  languages: PropTypes.array.isRequired,
};
