import PropTypes from "prop-types";
import React from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
} from "@chakra-ui/react";

export const TableTemplate = (props) => {
  console.log(props);
  const theads = props.theads;
  const tdata = props.tdata;
  return (
    <TableContainer whiteSpace="wrap">
      <Table
        variant="striped"
        colorScheme={"gray"}
        // pl={4}
        // pr={4}
        size="sm"
        rounded="md"
        border="1px solid"
        borderColor="gray.200"
        d="inline-block"
        pt={4}
      >
        <Thead>
          <Tr>
            {theads.map((thead) => (
              <Th fontWeight={"medium"} fontSize={15} pb={2}>
                {thead}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tdata.map((row) => {
            {
              return (
                <Tr>
                  <Td fontSize="md" pb={3} pt={3}>
                    {row.no}
                  </Td>
                  <Td fontSize="md" pb={3} pt={3}>
                    {row.document}
                  </Td>
                  <Td fontSize="md" pb={3} pt={3}>
                    {row.reason.map((r) => {
                      return <Text pb={3}>{r}</Text>;
                    })}
                  </Td>
                  <Td fontSize="md" pb={3} pt={3}>
                    {row.hoa}
                  </Td>
                </Tr>
              );
            }
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

TableTemplate.propTypes = {
  theads: PropTypes.array.isRequired,
  tdata: PropTypes.array.isRequired,
};
