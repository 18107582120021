export var acceptable_use_policy = {
  text: "Acceptable Use Policy",
  definition: "means our acceptable use policy which can be accessed here",
};

export var acquiring_bank = {
  text: "Acquiring Bank",
  definition:
    "means any bank(s), financial institution(s) or other partner(s) (as may change from time to time) that have a relationship with any Network through which we are able to make the Safepay Services available to you. Where the singular term “Acquiring Bank” is used, this refers to any and all entities through which we are able to access any and all Networks.",
};

export var acquiring_bank_charges = {
  text: "Acquiring Bank Charges",
  definition:
    "means any charges, fees, costs or expenses that are payable to the Acquiring Bank due to your use of the Safepay Services in connection with a Transaction.",
};

export var application = {
  text: "Application",
  definition:
    "means the process after you have created a Safepay Account with Restricted Access and you submit the Registration Documents and/or any other information as part of these Terms of Service for us to provide you a Safepay Account with Unrestricted Access.",
};

export var available_net_funds = {
  text: "Available Net Funds",
  definition:
    "means Funds minus any Safepay Charges (the value of which are shown in your Production Safepay Account) that are transferred to your Linked Bank Account.",
};

export var bank_account_maintenance_certificate = {
  text: "Bank Account Maintenance Certificate",
  definition:
    "means the document issued by your bank which identifies your account number, account name, date of account opening provided by you to confirm your Linked Bank Account.",
};

export var business_day = {
  text: "Business Day",
  definition:
    "means a day (other than a Saturday or Sunday or public holiday) on which banks are open for business in Pakistan.",
};

export var card_payment_method = {
  text: "Card Payment Method",
  definition:
    "means a Transaction in which your Customer pays you using a Visa, Mastercard or UnionPay enabled credit or debit card.",
};

export var charge = {
  text: "Charge",
  definition:
    "means an instruction to capture Funds from an account that a Customer maintains with a bank or other financial institution through which they pay you using the Safepay Services in connection with a Transaction.",
};

export var chargeback = {
  text: "Chargeback",
  definition:
    "means when a Customer raises a dispute with their card/payment instrument issuing institution (such as a bank). This usually occurs when a merchant fails to provide the product or service that was part of a Transaction to the Customer, or where the payment card/instrument holder did not authorize the Charge.",
};

export var customer = {
  text: "Customer",
  definition:
    "means any person (natural or legal) that attempts to and/or uses the Safepay Services to pay you in connection with a Transaction.",
};

export var demonstration_safepay_account = {
  text: "Demonstration Safepay Account",
  definition:
    "means a sandbox Safepay account created on our Website for demonstration and testing purposes and which does not allow real payments to be made to you.",
};

export var direct_bank_payment_method = {
  text: "Direct Bank Payment Method",
  definition:
    "means a Transaction in which your Customer pays you using their bank account or mobile wallet present in Pakistan.",
};

export var direct_settlement = {
  text: "Direct Settlement",
  definition:
    "means the process through which we credit your Linked Bank Account following a Transaction in which your Customer pays you using a Direct Bank Payment Method.",
};

export var effective_date = {
  text: "Effective Date",
  definition:
    "means (i) for a Demonstration Safepay Account and/or a Safepay Account with Restricted Access,  the date you create such account and agree to our Terms of Service (ii) for a Safepay Account with Unrestricted Access, the date you sign our Terms of Service electronically.",
};

export var force_majeure_event = {
  text: "Force Majeure Event",
  definition:
    "means an event beyond the control of the parties to these Terms of Service which prevents them from performing their respective obligations under these Terms of Service such as an act of God (such as, but not limited to, fires, explosions, earthquakes, drought, tidal waves and floods).",
};

export var funds = {
  text: "Funds",
  definition:
    "means the gross amount you charge a Customer in connection with a Transaction that are processed using the Safepay Services.",
};

export var laws = {
  text: "Law",
  definition: "means any laws, rules, and regulations.",
};

export var linked_bank_account = {
  text: "Linked Bank Account",
  definition:
    "means the bank account that you have connected to your Production Safepay Account to transfer any Available Net Funds.",
};

export var network = {
  text: "Network",
  definition:
    "means Visa, Mastercard, UnionPay or any other organisation through which we make the Safepay Services available to you and collect the Funds from a Customer.",
};

export var network_charges = {
  text: "Network Charges",
  definition:
    "means any fees, costs or expenses that are payable to the Networks due to the provision of the Safepay Services to you receiving payment from a Customer in connection with a Transaction.",
};

export var network_rules = {
  text: "Network Rules",
  definition:
    "means the rules and regulations of any Network with which we are required to comply with as a condition to making the Safepay Services available to you.",
};

export var notice = {
  text: "Notice",
  definition:
    "means a notice sent by either you or us in accordance with our Terms of Service.",
};

export var payment_method = {
  text: "Payment Method",
  definition:
    "means either: (1) a Card Payment Method or (2) a Direct Bank Payment Method (depending on which of the Safepay Services you use).",
};

export var person = {
  text: "Person",
  definition:
    "means any natural or legal person. A legal person can be any validly registered sole-proprietors, private limited companies, public limited companies, partnerships, validly registered non-profit organizations, trusts, societies and voluntary social welfare agencies.",
};

export var privacy_policy = {
  text: "Privacy Policy",
  definition: "means our privacy policy which can be accessed here.",
};

export var production_safepay_account = {
  text: "Production Safepay Account",
  definition:
    "means a production Safepay account created on our Website that is capable of accepting real payments from Customers in relation to a Transaction.",
};

export var registration_documents = {
  text: "Registration Documents",
  definition: "has the meaning given to it in our Terms of Service.",
};

export var regulator = {
  text: "Regulator",
  definition: "means the State Bank of Pakistan.",
};

export var reserve = {
  text: "Reserve",
  definition:
    "means the situation when we may require you to place funds in reserve in the Safepay Settlement Account (or any other bank account) or to impose conditions on the release of Available Net Funds from the Safepay Settlement Account to you.",
};

export var reversal = {
  text: "Reversal",
  definition:
    "means an instruction initiated by an Acquiring Bank, Network or us to return Available Net Funds to a Customer for an existing Charge. Reversals may result from (i) invalidation of a charge by an Acquiring Bank or a Network; (ii) submission of a Charge in violation of the applicable Network Rules, or where submission of the Charge or your use of the Safepay Services violates these Terms of Service.",
};

export var restricted_access = {
  text: "Restricted Access",
  definition:
    "means a Safepay Account that is not able to accept real payments.",
};

export var safepay_account = {
  text: "Safepay Account",
  definition:
    "means any Demonstration Safepay Account and/or Production Safepay Account.",
};

export var safepay_accounts = {
  text: "Safepay Accounts",
  definition:
    "means all accounts that you have open with us if you have more than one Safepay Account with us.",
};

export var safepay_charges = {
  text: "Safepay Charges",
  definition:
    "means the amount you must pay Safepay as listed in our Terms of Service we sign with you that are agreed between us in exchange for making the Safepay Services available to you. The Safepay Charges are a blended rate of the Network Charges and the Safepay Charges. The Safepay Charges are exclusive of any applicable Taxes.",
};

export var safepay_ip = {
  text: "Safepay IP",
  definition:
    "means (1) any intellectual property embodied in the Software and (2) any trademarks owned by Safepay to identify the Safepay Services.",
};

export var safepay_software = {
  text: "Safepay Software",
  definition:
    "means our APIs, documentation, developer’s toolkit, and other software applications.",
};

export var safepay_dashboard = {
  text: "Safepay Dashboard",
  definition:
    "means the dashboard you have access to when you log-in to your Safepay Account.",
};

export var safepay_settlement_account = {
  text: "Safepay Settlement Account",
  definition:
    "means any bank account opened with a third-party settlement bank in compliance with the rules of the Regulator for the purposes of settlement of a Transaction.",
};

export var safepay_services = {
  text: " Safepay Services",
  definition:
    "means payment facilitation, processing and/or other business services that may be offered by us to you.",
};

export var settlement_schedule = {
  text: "Settlement Schedule",
  definition:
    " means the timelines listed in our Terms of Service we sign with you which describe how Available Net Funds should settle in your Linked Bank Account following a Transaction.",
};

export var sub_agent = {
  text: "Sub-Agent",
  definition:
    "means any entity we appoint as sub-agent on your behalf to provide and perform the Services pursuant to the provisions of our Terms of Service.",
};

export var tax = {
  text: "Tax",
  definition:
    "mean any taxes, customs, duties, fees and other charges imposed by any governmental authority, including any value added tax, goods and services tax, provincial sales tax, federal sales tax and/or withholding tax on us providing the Services to you.",
};

export var third_party_representative = {
  text: "Third-Party Representative",
  definition:
    "means any Person that creates a Safepay Account on behalf of a User.",
};

export var transaction = {
  text: "Transaction",
  definition:
    "means any particular purchase, sale, donation, order, or other transaction that takes place as part of your business or any other activities for which you are paid using the Services.",
};

export var transaction_amount = {
  text: "Transaction Amount",
  definition:
    "means the aggregate amount paid by a Customer for a Transaction using a Payment Method.",
};

export var unrestricted_access = {
  text: "Unrestricted Access",
  definition: "means a Safepay Account that is able to accept real payments.",
};

export var website = {
  text: "Website",
  definition: "means our website which can be accessed",
  url: "https://www.getsafepay.pk/",
};
