export function getCodeBlockDetails(language, params) {
  switch (language) {
    case "react":
      return {
        language: "react",
        preview: true,
        codeBlocks: [
          {
            id: 0,
            code: `
        <html>
    <head>
        <!-- ..... -->
        <script src="https://unpkg.com/@sfpy/checkout-components
        @0.1.0/dist/sfpy-checkout.js"></script>
        <!-- ..... -->
    </head>
    <body>
        <!-- ..... -->
    </body>
</html>    
`,
            language: "html",
            title: "index.html",
          },
          {
            id: 1,
            code: `
        import safepay from "@sfpy/checkout-components";
import React from "react";
import ReactDOM from "react-dom";

const SafepayButtonInstance = safepay.Button.driver("react", {
React: React,
ReactDOM: ReactDOM,
});

export default SafepayButtonInstance;
        `,
            language: "jsx",
            title: "SafepayButtonInstance.jsx",
          },
          {
            id: 2,
            code: `
          import SafepayButtonInstance from './safepay-button-intance'

const MyCheckoutPage = ({ orderId }) => (
    <div>
        <p>Checkout for order id {orderId}</p>
        <SafepayButtonInstance
            env={'sandbox'}
            client={{
            'sandbox': 'sec_733defcf-835f-4cd1-99fd-b3e62dd83fe0'
            }}
            style={{
              mode: 'light',
              size: 'medium',
              variant: 'primary'
            }}
            orderId={"12344"},
            payment={{
              "currency": "${params.currency}",
              "amount": ${params.amount},
            }}
            onPayment={(data) => {
            // At this point your customer has approved the payment
            // and you can show a success message or make an API request
            // to your servers to add the data.
            return fetch("/api/orders/mark-paid", {
                method: "post",
            })
            }}
            onCancel={() => {
            console.log('payment cancelled')
            }}
        />
    </div>
)
        `,
            language: "jsx",
            title: "MyCheckoutPage.jsx",
          },
        ],

        steps: [
          {
            id: 0,
            heading: "1. Install through NPM or Yarn",
            explanation: [
              "In your project root you can run either one of the below commands (depending on what package manager you use)",
            ],
            code: [
              {
                id: 0,
                text: "Install using Yarn:",
                name: "Yarn",
                script: "yarn add @sfpy/checkout-components",
              },
              {
                id: 1,
                text: "Install using NPM:",
                name: "NPM",
                script: "npm i --save @sfpy/checkout-components",
              },
            ],
            linesToHighlight: [],
            tab: "index.html",
          },
          {
            id: 1,
            heading: "2. Add a script tag",
            explanation: [
              "You can choose whichever version suits your integration from the releases",
            ],
            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 4
            ),
            tab: "index.html",
          },
          {
            id: 2,
            heading: "3. Import Safepay library",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 1
            ),
            tab: "SafepayButtonInstance.jsx",
          },
          {
            id: 3,
            heading: "4. Define the Button Instance",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 4 },
              (_, i) => i + 5
            ),
            tab: "SafepayButtonInstance.jsx",
          },
          {
            id: 4,
            heading: "5. Export SafepayButtonInstance as default",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 10
            ),
            tab: "SafepayButtonInstance.jsx",
          },
          {
            id: 5,
            heading:
              "6. Import SafepayButtonInstance Component in your component",
            explanation: [
              "And then in another file you import SafepayButtonInstance and render is using familiar React and JSX.",
            ],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 1
            ),
            tab: "MyCheckoutPage.jsx",
          },
          {
            id: 6,
            heading: "7. Set currency and amount",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 18
            ),
            tab: "MyCheckoutPage.jsx",
            input: [
              {
                name: "Currency",
                fieldType: "select",
                options: ["USD", "PKR", "GBP", "AED", "EUR", "SAR"],
                placeholder: "Select currency",
              },
              {
                name: "Amount",
                fieldType: "number-input",
                min: 0,
                max: 10000,
                placeholder: "Enter amount",
              },
            ],
          },
          {
            id: 7,
            heading:
              "8. Add your logic inside the onPayment() method",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 21
            ),
            tab: "MyCheckoutPage.jsx",
          },
          {
            id: 8,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 9,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 10,
            heading: "Feedback",
            explanation: [
              "What do you think of our integration document? Let us know here support@getsafepay.com",
            ],
            doesNotGetSelected: true,
          },
        ],
      };

    case "javascript":
      return {
        language: "javascript",
        preview: true,
        codeBlocks: [
          {
            id: 0,
            code: `
        <html>
    <head>
        <!-- ..... -->
        <script src="https://unpkg.com/@sfpy/checkout-components
        @0.1.0/dist/sfpy-checkout.js"></script>
        <!-- ..... -->
    </head>
    <body>
        <!-- ..... -->
    </body>
</html>    `,
            language: "html",
            title: "index.html",
          },
          {
            id: 1,
            code: `
        <!DOCTYPE html>
<html>
<head>
    <title>Safepay checkout demo</title>
    <script
    type="text/javascript"
    src="https://unpkg.com/@sfpy/checkout-components@0.1.0/dist
    /sfpy-checkout.js"></script>
</head>
<body>
    <!-- Set up a container element for the button -->
    <div id="safepay-button-container"></div>
    <script>
    safepay
        .Button({
        env: "sandbox",
        client: {
            sanbox: "sec_733defcf-835f-4cd1-99fd-b3e62dd83fe0",
        },
        style: {
            mode: "light", // dark or light
            size: "large", // small medium large
            variant: "primary", // primary dark
        },
        orderId: "12344", // Your custom order ID
        source: "website" // The source of the payment
        // Details to set up the transaction
        // when a payment button is clicked
        payment: {
            currency: "${params.currency}",
            amount: ${params.amount},
        },
        // Finalize the transaction after payer approval
        onPayment: function (data) {
            // At this point your customer has approved the payment
            // and you can show a success message or make an API request
            // to your servers to add the data.
            return fetch("/api/orders/mark-paid", {
            method: "post",
            });
        },
        onCancel: function () {
            console.log("cancelled");
        },
        })
        .render("#safepay-button-container");
    </script>
</body>
</html> `,
            language: "html",
            title: "safepay-checkout.html",
          },
        ],

        steps: [
          {
            id: 0,
            heading: "1. Install through NPM or Yarn",
            explanation: [
              "In your project root you can run either one of the below commands (depending on what package manager you use)",
            ],
            code: [
              {
                id: 0,
                text: "Install using Yarn:",
                name: "Yarn",
                script: "yarn add @sfpy/checkout-components",
              },
              {
                id: 1,
                text: "Install using NPM:",
                name: "NPM",
                script: "npm i --save @sfpy/checkout-components",
              },
            ],
            linesToHighlight: [],
            tab: "index.html",
          },
          {
            id: 1,
            heading: "2. Add a script tag",
            explanation: [
              "Now add a script tag inside the head tag of your html file. You can choose whichever version suits your integration from the releases. The releases include v0.0.1, v0.0.2, v0.0.3, v0.0.5 and v0.0.9. You can find more abou the releases in our github repo the link to which is attached at the top of the page.",
            ],

            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 4
            ),
            tab: "index.html",
          },
          {
            id: 2,
            heading: "3. Define Safepay Button Instance",
            explanation: [
              "Now define Safepay Button Instance within the script tag inside the body tag of your html file or in a separate js file. Set the values of parameters accroding to your requirements.",
            ],
            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 14
            ),
            tab: "safepay-checkout.html",
          },
          {
            id: 3,
            heading: "4. Set currency and amount",
            explanation: [
              "Set the values for currency and amount. The currencies that we support include USD, PKR, GBP, AED, EUR, and SAR. You can choose one of these from the dropdown. the value for amount should be greater than 0.",
            ],
            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 30
            ),
            tab: "safepay-checkout.html",
            input: [
              {
                name: "Currency",
                fieldType: "select",
                options: ["USD", "PKR", "GBP", "AED", "EUR", "SAR"],
                placeholder: "Select currency",
              },
              {
                name: "Amount",
                fieldType: "number-input",
                min: 0,
                max: 10000,
                placeholder: "Enter amount",
              },
            ],
          },
          {
            id: 4,
            heading:
              "5. Add your logic inside the onPayment() method",
            explanation: [
              "The onPayment() function is called when your customer has approved the payment. Inside your onPayment(), you can show a success message or make an API request to your servers to add the data.",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 34
            ),
            tab: "safepay-checkout.html",
          },
          {
            id: 5,
            heading: "Checkout Preview",
            explanation: [
              "You can see Safepay Checkout button preview by opening the dropdown at the top of the code block window. By entering your Merchant API Key and clicking the Safepay Checkout button, you will be redirected to Safepay's checkout page and can make a test payment.",
            ],
            doesNotGetSelected: true,
          },

          {
            id: 6,
            heading: "Github Repository",
            explanation: [
              "You can find more about our Javascript SDK here:",
            ],
            href: {
              text: "JavaScript SDK Github",
              link:
                "https://github.com/getsafepay/safepay-checkout-components",
            },

            doesNotGetSelected: true,
          },

          {
            id: 7,
            heading: "Feedback",
            explanation: [
              "What do you think of our integration document? Let us know here support@getsafepay.com",
            ],
            doesNotGetSelected: true,
          },
        ],
      };

    case "nodejs":
      return {
        language: "nodejs",
        preview: false,
        codeBlocks: [
          {
            id: 0,
            code: `
          import { Safepay } from '@sfpy/node-sdk'
  
const safepay = new Safepay({
    environment: 'sandbox',
    apiKey: 'sec_asd12-2342s-1231s',
    v1Secret: 'bar',
    webhookSecret: 'foo'
})

const { token } = await safepay.payments.create({
    currency: "${params.currency}",
    amount: ${params.amount}  
})
// Pass 'token' to create checkout link

const url = safepay.checkout.create({
    token,
    orderId: 'T800',
    cancelUrl: 'http://example.com/cancel',
    redirectUrl: 'http://example.com/success',
    source: 'custom',
    webhooks: true
})
      `,
            language: "javascript",
            title: "payment.js",
          },
        ],
        steps: [
          {
            id: 0,
            heading: "1. Install through NPM or Yarn",
            explanation: [
              "In your project root you can run either one of the below commands (depending on what package manager you use)",
            ],
            code: [
              {
                id: 0,
                text: "Install using Yarn:",
                name: "Yarn",
                script: "yarn add @sfpy/node-sdk",
              },
              {
                id: 1,
                text: "Install using NPM:",
                name: "NPM",
                script: "npm install @sfpy/node-sdk",
              },
            ],
            linesToHighlight: [],
            tab: "payment.js",
          },
          {
            id: 1,
            heading: "2. Import",
            explanation: ["Import from the Safepay library"],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 1
            ),
            tab: "payment.js",
          },
          {
            id: 2,
            heading:
              "3. Create a Safepay client by passing your config",
            explanation: [
              "You can now create payments and checkout links.",
            ],
            linesToHighlight: Array.from(
              { length: 5 },
              (_, i) => i + 3
            ),
            tab: "payment.js",
          },
          {
            id: 3,
            heading: "4. Set currency and amount",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 5 },
              (_, i) => i + 10
            ),
            tab: "payment.js",
            input: [
              {
                name: "Currency",
                fieldType: "select",
                options: ["USD", "PKR", "GBP", "AED", "EUR", "SAR"],
                placeholder: "Select currency",
              },
              {
                name: "Amount",
                fieldType: "number-input",
                min: 0,
                max: 10000,
                placeholder: "Enter amount",
              },
            ],
          },
          {
            id: 4,
            heading: "5. Create checkout link",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 16
            ),
            tab: "payment.js",
          },
          {
            id: 5,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 6,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 7,
            heading: "Feedback",
            explanation: [
              "What do you think of our integration document? Let us know here support@getsafepay.com",
            ],
            doesNotGetSelected: true,
          },
        ],
      };
    case "python":
      return {
        language: "python",
        preview: false,
        codeBlocks: [
          {
            id: 0,
            code: `
        from "python_sdk" import safepay;

env = safepay.Safepay(
    {
        "environment": "sandbox",
        "apiKey": "sec_asd12-2342s-1231s",
        "v1Secret": "bar",
        "webhookSecret": "foo",
    }
)

payment_response = env.set_payment_details(
    {  
        "currency": "${params.currency}", 
        "amount": ${params.amount}
    }
)

token = payment_response["data"]["token"]
# Pass 'token' to create checkout link

checkout_url = env.get_checkout_url(
  {
      "beacon": token,
      "cancelUrl": "http://example.com/cancel",
      "orderId": "T800",
      "redirectUrl": "http://example.com/success",
      "source": "custom",
      "webhooks": True,
  }
)
# set webhooks = True if want to subscribe to webhooks
# redirect user to 'url'
    `,
            language: "python",
            title: "payment.py",
          },
          {
            id: 1,
            code: `
        signature_verification = env.is_signature_valid(
    { 
      "sig": "abcd", 
      "tracker": token
    }
)
# mark the invoice as paid if valid
# show an error if invalid

webhook_verification = env.is_webhook_valid(
    { 
      "x-sfpy-signature": "abcd"
    },
    { 
      "data": data
    }
)
# mark the invoice as paid if valid
# show an error if invalid
    `,
            language: "python",
            title: "verification.py",
          },
        ],
        steps: [
          {
            id: 0,
            heading: "1. Install through Pip",
            explanation: [
              "In your project root you can run the command below",
            ],
            code: [
              {
                id: 0,
                text: "Using Pip:",
                name: "Pip",
                script: "pip install safepay-python",
              },
            ],
            linesToHighlight: [],
            tab: "payment.py",
          },
          {
            id: 1,
            heading: "2. Import",
            explanation: [
              "Import Safepay class from safepay library",
            ],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 1
            ),
            tab: "payment.py",
          },
          {
            id: 2,
            heading:
              "3. Create a Safepay client by passing your config",
            explanation: [
              "You can now create payments and checkout links.",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 3
            ),
            tab: "payment.py",
          },
          {
            id: 3,
            heading: "4. Set currency and amount",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 6 },
              (_, i) => i + 12
            ),
            tab: "payment.py",
            input: [
              {
                name: "Currency",
                fieldType: "select",
                options: ["USD", "PKR", "GBP", "AED", "EUR", "SAR"],
                placeholder: "Select currency",
              },
              {
                name: "Amount",
                fieldType: "number-input",
                min: 0,
                max: 10000,
                placeholder: "Enter amount",
              },
            ],
          },
          {
            id: 4,
            heading: "5. Create checkout link",
            explanation: [
              "Pass the token received in payment response along with other parameters to generate checkout link. Redirect user to the checkout link.",
            ],
            linesToHighlight: Array.from(
              { length: 15 },
              (_, i) => i + 19
            ),
            tab: "payment.py",
          },
          {
            id: 5,
            heading: "6. Verify signature",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our.",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 1
            ),
            tab: "verification.py",
          },
          {
            id: 6,
            heading: "7. Verify webhook",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our.",
            ],
            linesToHighlight: Array.from(
              { length: 10 },
              (_, i) => i + 10
            ),
            tab: "verification.py",
          },
          {
            id: 7,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 8,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 9,
            heading: "Feedback",
            explanation: [
              "What do you think of our integration document? Let us know here support@getsafepay.com",
            ],
            doesNotGetSelected: true,
          },
        ],
      };
    case "react-native":
      return {
        language: "react-native",
        preview: true,
        codeBlocks: [
          {
            id: 0,
            code: `
            import React from 'react';
import SafepayCheckout from '@sfpy/react-native-sdk';
import {StyleSheet} from 'react-native';
            
const Home: React.FC = () => {
  return (
    <>
      <SafepayCheckout
        currency="${params.currency}"
        amount={${params.amount}}
        clientKey="sec_xxxx-yourkey"
        environment={environment.SANDBOX}
        order_id="12345"
        buttonStyle={styles.button}
        buttonTheme={theme.DEFAULT}
        onPaymentCancelled={() => Alert.alert('Payment Cancelled!')}
        onPaymentComplete={() => Alert.alert('Payment Successfull')}
        onErrorFetchingTracker={() => {
          Alert.alert('error fetching tracker id'
          )}
      />
    </>
  );
};
export default Home;
      `,
            language: "javascript",
            title: "payment.js",
          },
          {
            id: 1,
            code: `
            const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: 'lightblue',
    padding: 10,
  },
  btn_text: {
    color: 'black',
    fontSize: 15,
    fontWeight: 'bold',
  },
});
      `,
            language: "javascript",
            title: "styles.js",
          },
        ],
        steps: [
          {
            id: 0,
            heading: "1. Install through NPM or Yarn",
            explanation: [
              "In your project root you can run either one of the below commands (depending on what package manager you use)",
            ],
            code: [
              {
                id: 0,
                text: "Install using Yarn:",
                name: "Yarn",
                script: "yarn add @sfpy/react-native-sdk",
              },
              {
                id: 1,
                text: "Install using NPM:",
                name: "NPM",
                script: "npm install @sfpy/react-native-sdk",
              },
            ],
            linesToHighlight: [],
            tab: "payment.js",
          },
          {
            id: 1,
            heading:
              "2. Add react-native-webview to your dependencies",
            code: [
              {
                id: 0,
                text: "Install using Yarn:",
                name: "Yarn",
                script: "yarn add react-native-webview",
              },
              {
                id: 1,
                text: "Install using NPM:",
                name: "NPM",
                script: "npm install --save react-native-webview",
              },
            ],
            linesToHighlight: [],
            tab: "payment.js",
          },
          {
            id: 2,
            heading: "3. Import",
            explanation: [
              "Import Safepay class from safepay library",
            ],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 1
            ),
            tab: "payment.js",
          },
          {
            id: 3,
            heading:
              "4. Create a Safepay client by passing your config",
            explanation: [
              "You can now create payments and checkout links.",
            ],
            linesToHighlight: Array.from(
              { length: 20 },
              (_, i) => i + 5
            ),
            tab: "payment.js",
          },
          {
            id: 4,
            heading: "5. Set currency and amount",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 9
            ),
            tab: "payment.js",
            input: [
              {
                name: "Currency",
                fieldType: "select",
                options: ["USD", "PKR", "GBP", "AED", "EUR", "SAR"],
                placeholder: "Select currency",
              },
              {
                name: "Amount",
                fieldType: "number-input",
                min: 0,
                max: 10000,
                placeholder: "Enter amount",
              },
            ],
          },
          {
            id: 5,
            heading: "6. Export the component",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 25
            ),
            tab: "payment.js",
          },
          {
            id: 6,
            heading: "7. Implement your styling here",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            linesToHighlight: Array.from(
              { length: 12 },
              (_, i) => i + 1
            ),
            tab: "styles.js",
          },
          {
            id: 7,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 8,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 9,
            heading: "Feedback",
            explanation: [
              "What do you think of our integration document? Let us know here support@getsafepay.com",
            ],
            doesNotGetSelected: true,
          },
        ],
      };
    case "php":
      return {
        language: "php",
        preview: false,
        codeBlocks: [
          {
            id: 0,
            code: `
            
            $safepay = new \Safepay\SafepayClient([
  'api_key' => 'BQokikJOvBiI2HlWgH4olfQ2',
  'api_base' => 'https://sandbox.api.getsafepay.com'
]);

try {
    // You need to generate a tracker with mode 'instrument'
    // to tell Safepay that you wish to set up a tracker to
    // tokenize a customer's card
    $session = $safepay->order->setup([
        "merchant_api_key" => "sec_8dcac601-4b70-442d-b198-03aadd28f12b",
        "intent" => "CYBERSOURCE",
        "mode" => "instrument",
        "currency" => "PKR"
    ]);

    // You need to either create a customer or retreive the customer
    // from your backend so you have access to the customer ID
    $customer = $safepay->customer->create([
        //required
        "first_name" => "Hassan",
        "last_name" => "Zaidi",
        "email" => "hzaidi@getsafepay.com",
        "phone_number" => "+923331234567",
        "country" => "PK"
        //optional 
        "is_guest" => true 
    ]);

    // You can optionally create an address object if you have
    // access to the customer's billing details
    $address = $safepay->address->create([
        // required
        "street1" => "3A-2 7th South Street",
        "city" => "Karachi",
        "country" => "PK",
        // optional
        "postal_code" => "75500",
        "province" => "Sindh"
    ]);
    // You need to create a Time Based Authentication token
    $tbt = $safepay->passport->create();

    // Finally, you can create the Checkout URL
    $checkoutURL = \Safepay\Checkout::constructURL([
        "environment" => "production", // one of "development", "sandbox" or "production"
        "tracker" => $session->tracker->token,
        "user_id" => $customer->token,
        "tbt" => $tbt,
        "address" => $address->token,
        "source" => "mobile" // important for rendering in a WebView
    ]);
    echo($checkoutURL);
    return $checkoutURL;
} catch(\UnexpectedValueException $e) {
    // Invalid payload
    http_response_code(400);
    exit();
}

    `,
            language: "php",
            title: "payment.php",
          },
          {
            id: 1,
            code: `
  
            // Set your secret key. Remember to switch to your live secret key in production.
// See your keys here: https://getsafepay.com/dashboard/developers
\Safepay\Safepay::setApiKey('sec_4eC39HqLyjWDarjtT1zdp7dc');

// You can ind your endpoint's secret in your webhook settings in the Developer Dashboard
$webhook_secret = '234hjkasd....';

$payload = @file_get_contents('php://input');
$sig_header = $_SERVER['X-SFPY-SIGNATURE'];
$event = null;

try {
    $event = \Safepay\Webhook::constructEvent(
        $payload, $sig_header, $webhook_secret
    );
} catch(\UnexpectedValueException $e) {
    // Invalid payload
  http_response_code(400);
  echo json_encode(['Error parsing payload: ' => $e->getMessage()]);
  exit();
} catch(\Safepay\Exception\SignatureVerificationException $e) {
    // Invalid signature
    http_response_code(400);
    echo json_encode(['Error verifying webhook signature: ' => $e->getMessage()]);
    exit();
}

// Handle the event
switch ($event->type) {
    case 'payment.succeeded':
        $payment = $event->data;
        break;
    case 'payment.failed':
        $payment = $event->data;
        break;
    // ... handle other event types
    default:
        echo 'Received unknown event type ' . $event->type;
}

http_response_code(200);

    `,
            language: "php",
            title: "verify.php",
          },
        ],
        steps: [
          {
            id: 0,
            heading: "1. Install Bindings",
            explanation: [
              "Install bindings via Composer. If you do not wish to use Composer, you can download the latest release of our PHP sdk.",
            ],
            code: [
              {
                id: 0,
                text: "Install bindings through Composer:",
                name: "Composer",
                script: "composer require getsafepay/sfpy-php",
              },
            ],
            linesToHighlight: [],
            tab: "payment.php",
          },
          {
            id: 1,
            heading: "2. Use Bindings",
            explanation: [
              "Use binding manually or through Composer. In case of manual use, the bindings require the following extensions in order to work properly: 1) curl, although you can use your own non-cURL client if you prefer, 2) json, 3) mbstring (Multibyte String):",
            ],
            code: [
              {
                id: 0,
                text: "Use bindings through Composer:",
                name: "Composer",
                script: "require_once 'vendor/autoload.php';",
              },
              {
                id: 1,
                text: "Use bindings manually:",
                name: "Manual",
                script:
                  "require_once '/path/to/safepay-php/init.php';",
              },
            ],
            linesToHighlight: [],
            tab: "payment.php",
          },
          {
            id: 2,
            heading: "3. Get Public and Secret Key",
            explanation: [
              "To instantiate the tracker, the utilization of your public key is imperative, as detailed in the subsequent discussion. For authorization purposes, the inclusion of your secret key is mandatory. Retrieve both keys from the Developers section under API on your dashboard. Ensure precision in accessing the appropriate sandbox or production dashboards aligned with your operational environment.",
            ],
            linesToHighlight: [],
            tab: "payment.php",
          },
          {
            id: 3,
            heading: "4. Create a Safepay client",
            explanation: [
              "Create a Safepay client by passing your config. To use the SDK in a sandbox environment set the base_url to https://sandbox.api.getsafepay.com",
            ],
            linesToHighlight: Array.from(
              { length: 4 },
              (_, i) => i + 1
            ),
            tab: "payment.php",
          },
          {
            id: 4,
            heading: "5. Initiate a tracker",
            explanation: [
              "The Tracker serves as a singular identifier for individual payments, enabling the comprehensive tracking of the complete journey undergone by a specific payment. To activate the instrument element in your API requests, ensure that the mode in the request body is set to `mode:instrument`. It's crucial to note that distinct modes are applicable to each element, and these variations will be elaborated upon in their respective sections.",
            ],
            linesToHighlight: Array.from(
              { length: 6 },
              (_, i) => i + 10
            ),
            tab: "payment.php",
          },
          {
            id: 5,
            heading: "6. Create customer",
            explanation: [
              "A Customer Token is vital for enrollment. Verify no duplicate entries before creating a new customer. Set is_guest = false for permanent card storage, or is_guest = true for no storage. This config ensures card details are kept for 24 hours, auto-deleting after a successful payment or within the specified timeframe for incomplete payments.",
            ],
            linesToHighlight: Array.from(
              { length: 10 },
              (_, i) => i + 19
            ),
            tab: "payment.php",
          },
          {
            id: 6,
            heading: "7. Generate address token",
            explanation: [
              "Including the address is crucial for successful transactions, impacting payment success. Furnishing all necessary details streamlines checkout by minimizing address fields. Enhance customer experience by thorough submission, reducing the need for manual address entry. Ensure comprehensive information is provided in the specified section.",
            ],
            linesToHighlight: Array.from(
              { length: 9 },
              (_, i) => i + 32
            ),
            tab: "payment.php",
          },
          {
            id: 7,
            heading: "8. Generate TBT",
            explanation: [
              "Employ your secret key to generate a Time-Based Token (TBT). The TBT is a prerequisite for authenticating the URL during the loading process of our embedded checkout.",
            ],
            linesToHighlight: Array.from(
              { length: 1 },
              (_, i) => i + 42
            ),
            tab: "payment.php",
          },
          {
            id: 8,
            heading: "9. Generate Checkout URL",
            explanation: [
              "Following parameters are required generate URL to render the Safepay instrument element",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 45
            ),
            tab: "payment.php",
          },
          {
            id: 9,
            heading: "10. Webhooks",
            explanation: [
              "To enable Safepay integrations, register webhook endpoints for real-time event data transmission. Webhooks deliver HTTPS-secured JSON payloads, including Event objects, for monitoring asynchronous occurrences like payment confirmation or charge disputes. Establish server endpoints for Safepay webhooks, perform actions based on received event types, and send a 200 response code for successful receipt confirmation. Failure to provide a 200 response code prompts Safepay to resend the webhook.",
            ],
            linesToHighlight: Array.from(
              { length: 24 },
              (_, i) => i + 3
            ),
            tab: "verify.php",
          },
          {
            id: 10,
            heading: "11. Handle the event",
            explanation: [
              "Note Safepay requires the raw body of the request to perform signature verification. If you’re using a framework, make sure it doesn’t manipulate the raw body. Any manipulation to the raw body of the request causes the verification to fail.",
            ],
            linesToHighlight: Array.from(
              { length: 13 },
              (_, i) => i + 29
            ),
            tab: "verify.php",
          },
          {
            id: 11,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 12,
            heading: "Ending Step",
            explanation: [
              "Since we're huge fans of React at Safepay, we've integrated this library into our products like Quick Links through the following React integration that ships out of the box with this library",
            ],
            doesNotGetSelected: true,
          },
          {
            id: 13,
            heading: "Feedback",
            explanation: [
              "What do you think of our integration document? Let us know here support@getsafepay.com",
            ],
            doesNotGetSelected: true,
          },
        ],
      };
    case "java":
      return {
        language: "java",
        preview: true,
        codeBlocks: [
          {
            id: 0,
            code: `
            <WebView
android:id="@+id/webview"
android:layout_width="match_parent"
android:layout_height="match_parent"
/>  `,
            language: "java",
            title: "activity_main.xml",
          },
          {
            id: 1,
            code: `
            WebView webView = findViewById(R.id.webview);
webView.getSettings().setJavaScriptEnabled(true); 
webView.loadUrl("https://www.safepaycheckouturl.com");

webView.setWebViewClient(new WebViewClient() {
  @Override
  public void doUpdateVisitedHistory(WebView view, String url, boolean isReload) {
      super.doUpdateVisitedHistory(view, url, isReload);
      WebHistoryItem historyItem = view.copyBackForwardList().getCurrentItem();
      String currentUrl = historyItem.getUrl();

      // In case of success on Safepay Hosted Checkout, check if the url contains
      if (currentUrl.contains("/external/mobile")) {
          // URL has changed
          // Sample URL
          // https://sandbox.api.getsafepay.com/checkout/external/mobile?env=development&source=mobile
          // &beacon=track_fd2fdcd2-b5ca-4c1f-b703-01ccb38b92a1&tracker=track_fd2fdcd2-b5ca-4c1f-b703-01ccb38b92a1
          // &sig=e224792a7fa3f52da74a9cbedae9f58b27e59064d248a7217b61bb13321da431&action=complete
          
           if ("complete".equals(action)) {
              // Code to close Safepay Checkout
          }
      }

      // In case of an error on Safepay Hosted Checkout, check if the url contains
      if (currentUrl.contains("/external/error")) {
          // URL has changed
          //Sample URL
          //https://sandbox.api.getsafepay.com/checkout/external/error?error=Tracker%20is%20in%20an%20invalid%20state

          // Code to close Safepay Checkout
      }

      // In case of cancellation on Safepay Hosted Checkout, check if the url contains
      if (currentUrl.contains("/external/mobile")) {
          // URL has changed
          // Sample URL
          // https://sandbox.api.getsafepay.com/checkout/external/mobile?env=development&order_id=
          // &source=mobile&beacon=track_c3103a8f-9ef2-43ee-8fc0-6c7fd01025c7&action=cancelled
          
          if ("complete".equals(action)) {
              // Code to close Safepay Checkout
          }
      }

      // In case of success on Safepay Embedded Checkout, check if the url contains
      if (currentUrl.contains("/external/complete")) {
          // URL has changed
          // Sample URL   
          // https://sandbox.api.getsafepay.com/embedded/external/complete?environment=sandbox
          // &tbt=6Qw_YaQ304iuB-Ad3UXicBOQwYT33huFj3I1zLYrkbRmpiz3ce2dD2Up3xDWoxeS8mivGmW17g%3D%3D
          // &tracker=track_a7d2bc6d-3b8f-4643-aae5-4f506307075b&finalizer_message=Card%20saved%20successfully&action=complete
          
          // Code to close Safepay Checkout
      }

      // In case of an error on Safepay Embedded Checkout, check if the url contains
      if (currentUrl.contains("/external/error")) {
          // URL has changed
          // Sample URL   
          // https://sandbox.api.getsafepay.com/embedded/external/error?environment=sandbox&tbt=6Qw_YaQ304iuB-Ad3UXicBOQwYT33huFj3I1zLYrkbRmpiz3ce2dD2Up3xDWoxeS8mivGmW17g%3D%3D
          // &tracker=track_5e4f5be3-f235-47ef-82a7-8c0c680ea195&finalizer_message=error%20performing%20action%20%27AUTHORIZATION%27%3A%20cannot%20add%20payment%20method%3A%20duplicate%20
          
          // Code to close Safepay Checkout
      }
  }
});`,
            language: "java",
            title: "safepay-checkout.java",
          },
        ],

        steps: [
          {
            id: 0,
            heading: "1. Add WebView to your layout XML file",
            explanation: [
              "Open the XML layout file (activity_main.xml or any other layout file) and add a WebView element.",
            ],
            linesToHighlight: Array.from(
              { length: 5 },
              (_, i) => i + 1
            ),
            tab: "activity_main.xml",
          },
          {
            id: 1,
            heading: "2. Load URL in WebView",
            explanation: [
              "In your Java code, you can load a URL into the WebView by finding the WebView instance and calling the loadUrl method.",
            ],

            linesToHighlight: Array.from(
              { length: 3 },
              (_, i) => i + 1
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 2,
            heading: "3. Create a WebViewClient",
            explanation: [
              "If you want to detect changes in the URL history of a WebView, you can use the doUpdateVisitedHistory method in combination with the WebHistoryItem class. Create a WebViewClient and override the doUpdateVisitedHistory method",
            ],
            linesToHighlight: Array.from(
              { length: 4 },
              (_, i) => i + 5
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 3,
            heading: "4. Use the WebHistoryItem",
            explanation: [
              "Use the WebHistoryItem class to access the URL history items. ",
            ],
            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 9
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 4,
            heading:
              "5. Check URL in case of success on Safepay Hosted Checkout",
            explanation: [
              "Check if the URL contains `/external/mobile`. If it does, parse the `action` query parameter in `currentUrl`. If the value of `action` is 'complete', you can safely close the Safepay Checkout.",
            ],
            linesToHighlight: Array.from(
              { length: 12 },
              (_, i) => i + 12
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 5,
            heading:
              "6. Check URL in case of an error on Safepay Hosted Checkout",
            explanation: [
              "Check if the url contains /external/error. If yes, you can safely close Safepay Checkout.",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 25
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 6,
            heading:
              "7. Check URL in case of cancellation on Safepay Hosted Checkout",
            explanation: [
              "Check if the URL contains `/external/mobile`. If it does, parse the `action` query parameter in `currentUrl`. If the value of `action` is 'cancelled', you can safely close the Safepay Checkout.",
            ],
            linesToHighlight: Array.from(
              { length: 11 },
              (_, i) => i + 34
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 7,
            heading:
              "8. Check URL in case of success on Safepay Embedded Checkout",
            explanation: [
              "Check if the url contains /external/complete. If yes, you can safely close Safepay Checkout.",
            ],
            linesToHighlight: Array.from(
              { length: 10 },
              (_, i) => i + 46
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 8,
            heading:
              "9. Check URL in case of an error on Safepay Embedded Checkout",
            explanation: [
              "Check if the url contains /external/error If yes, you can safely close Safepay Checkout.",
            ],
            linesToHighlight: Array.from(
              { length: 11 },
              (_, i) => i + 57
            ),
            tab: "safepay-checkout.java",
          },
          {
            id: 9,
            heading: "Feedback",
            explanation: [
              "What do you think of our integration document? Let us know here support@getsafepay.com",
            ],
            doesNotGetSelected: true,
          },
        ],
      };
    case "swift":
      return {
        language: "swift",
        preview: true,
        codeBlocks: [
          {
            id: 0,
            code: `
          // Properties
@property (weak, nonatomic) IBOutlet UIView *webMainView;
@property (strong, nonatomic) WKWebView *safePayWebView;

BOOL isSuccessAlreadyFired;
BOOL isSuccess;
BOOL isFailure;

NSString *safePayURL;
NSArray * success_array;
NSArray * failure_array;


- (void)viewDidAppear:(BOOL)animated {
    [super viewDidAppear:YES];
    
    // Changed URL in case of success on Safepay Hosted Checkout
    safePayURL = @"https://sandbox.api.getsafepay.com/checkout/external/mobile?env=development&source=mobile&beacon=track_fd2fdcd2-b5ca-4c1f-b703-01ccb38b92a1&tracker=track_fd2fdcd2-b5ca-4c1f-b703-01ccb38b92a1&sig=e224792a7fa3f52da74a9cbedae9f58b27e59064d248a7217b61bb13321da431&action=complete";

    // Changed URL in case of an error on Safepay Hosted Checkout
    safePayURL = @"https://sandbox.api.getsafepay.com/checkout/external/error?error=Tracker%20is%20in%20an%20invalid%20state";

    // Changed URL in case of success on Safepay Embedded Checkout
    safePayURL = @"https://sandbox.api.getsafepay.com/embedded/external/complete?environment=sandbox&tbt=6Qw_YaQ304iuB-Ad3UXicBOQwYT33huFj3I1zLYrkbRmpiz3ce2dD2Up3xDWoxeS8mivGmW17g%3D%3D&tracker=track_a7d2bc6d-3b8f-4643-aae5-4f506307075b&finalizer_message=Card%20saved%20successfully&action=complete";

    // Changed URL in case of success on Safepay Embedded Checkout
    safePayURL = @"https://sandbox.api.getsafepay.com/embedded/external/error?environment=sandbox&tbt=6Qw_YaQ304iuB-Ad3UXicBOQwYT33huFj3I1zLYrkbRmpiz3ce2dD2Up3xDWoxeS8mivGmW17g%3D%3D&tracker=track_5e4f5be3-f235-47ef-82a7-8c0c680ea195&finalizer_message=error%20performing%20action%20%27AUTHORIZATION%27%3A%20cannot%20add%20payment%20method%3A%20duplicate%20&action=complete";
    
    // Add success/failure keywords on completion
    success_array = [@"success", @"success/done", @"complete", @"external/complete", @"external/mobile"];
    failure_array = [@"failure", @"failed", @"not_processed", @"external/error"];
    
    [self createSafePayWkWebView];
}

-(void)createSafePayWkWebView{
    
    WKWebViewConfiguration *theConfiguration = [[WKWebViewConfiguration alloc] init];
    self.safePayWebView = [[WKWebView alloc]initWithFrame:CGRectMake(0, 0, self.webMainView.bounds.size.width, self.webMainView.bounds.size.height) configuration:theConfiguration];
    
    self.safePayWebView.navigationDelegate = self;
    
    NSURL *url = [NSURL URLWithString:safePayURL];
    NSURLRequest *request = [NSURLRequest requestWithURL:url];
    [self.safePayWebView loadRequest:request];
    
    [self.safePayWebView addObserver:self forKeyPath:@"URL" options:NSKeyValueObservingOptionNew context:nil];
    [self.safePayWebView addSubview:self.webMainView];
}

- (void)observeValueForKeyPath:(NSString *)keyPath ofObject:(id)object change:(NSDictionary<NSKeyValueChangeKey,id> *)change context:(void *)context {
    
    if ([keyPath isEqualToString:@"URL"] && object == self.safePayWebView) {
        
        NSURL *currentURL = [change objectForKey:NSKeyValueChangeNewKey];
        NSString *redirectedURL = currentURL.absoluteString;
        
        isSuccess = NO;
        isFailure = NO;
        
        // Compare Success URL's
        for (NSString * strSuccess in success_array) {
            
            NSRange range = [redirectedURL rangeOfString:strSuccess];
            
            if (range.location != NSNotFound) {
                
                // NSLog(@"success url: %@", redirectedURL);
                isSuccess = YES;
                isFailure = NO;
            }
        }
        
        // Compare Failure URL's
        for (NSString * strFailure in failure_array) {
            
            NSRange range = [redirectedURL rangeOfString:strFailure];
            
            if (range.location != NSNotFound) {
                
                //  NSLog(@"failure url: %@", redirectedURL);
                isFailure = YES;
                isSuccess = NO;
            }
        }
        
        if (isSuccess || isFailure){
            [self performSelector:@selector(doCompletionAction) withObject:self afterDelay:2.0 ];
        }
        
    } else {
        [super observeValueForKeyPath:keyPath ofObject:object change:change context:context];
    }
}

-(void)doCompletionAction{
    
    if (!isSuccessAlreadyFired) {
        
        isSuccessAlreadyFired = YES;
        
        if (isSuccess){
            // Deal success block here
            
        } else {
            
            if (isFailure){
                // Deal failure block here
            }
        }
    }
}
`,
            language: "swift",
            title: "safepay-checkout.m",
          },
        ],
        steps: [
          {
            id: 0,
            heading: "1. Create an IBOutlet for the main view",
            explanation: [
              "Create an IBOutlet for the main view and declare a property for the WKWebView.",
            ],
            linesToHighlight: Array.from(
              { length: 3 },
              (_, i) => i + 1
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 1,
            heading: "2. Variable declarations",
            explanation: [
              "Next, you declare several variables that are essential for tracking the state and managing the web view. These include flags to monitor success and failure states, as well as arrays to store the success and failure keywords.",
            ],

            linesToHighlight: Array.from(
              { length: 7 },
              (_, i) => i + 5
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 2,
            heading: "3. Setup of URL and Success/Failure Keywords",
            explanation: [
              "When the view appears, this method sets up the URL and defines the success and failure keywords that will be used when the view appears.",
            ],

            linesToHighlight: Array.from(
              { length: 21 },
              (_, i) => i + 14
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 3,
            heading:
              "4. Configure the Web View to Load the Safepay URL",
            explanation: [
              "This method initializes and configures the web view to load the Safepay URL. It sets up the web view, loads the specified URL, and adds an observer to monitor changes in the URL.",
            ],
            linesToHighlight: Array.from(
              { length: 14 },
              (_, i) => i + 36
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 4,
            heading: "5. URL Check",
            explanation: [
              "Inside the observation method, it retrieves the new URL from the change dictionary and checks whether it contains any success or failure URLs from the `success_array` and `failure_arrays` arrays.",
            ],
            linesToHighlight: Array.from(
              { length: 2 },
              (_, i) => i + 55
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 5,
            heading: "6. Handle success",
            explanation: [
              "The code loops through the success URL addresses obtained from `success_array`. It checks each address to see if the redirected URL contains it. If a match is found, the isSuccess flag is set to true, indicating a successful URL match. Additionally, the isFailure flag is set to false to signify no failure.",
            ],
            linesToHighlight: Array.from(
              { length: 12 },
              (_, i) => i + 61
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 6,
            heading: "7. Handle Failure",
            explanation: [
              "Similarly, the code iterates through the failure URL addresses retrieved from `failure_array`. For each address, it examines whether the redirected URL contains it. If a match is detected, the isFailure flag is set to true, indicating a failure URL match. Simultaneously, the isSuccess flag is set to false to denote the absence of a success.",
            ],
            linesToHighlight: Array.from(
              { length: 12 },
              (_, i) => i + 74
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 7,
            heading: "8. Delay and Action",
            explanation: [
              "If a success or failure URL is found, it schedules the doCompletionAction method to be called after a delay of 2 seconds. This is necessary because the observer triggers rapidly with each URL change. The delay will ensure that only one condition is met before performing your action.",
            ],
            linesToHighlight: Array.from(
              { length: 8 },
              (_, i) => i + 87
            ),
            tab: "safepay-checkout.m",
          },
          {
            id: 8,
            heading: "9. Completion Action",
            explanation: [
              "The doCompletionAction method is responsible for handling the completion action based on whether the URL indicates success or failure. It ensures that the action is executed only once (isSuccessAlreadyFired flag), and then executes the appropriate block of code for success or failure.",
            ],
            linesToHighlight: Array.from(
              { length: 17 },
              (_, i) => i + 96
            ),
            tab: "safepay-checkout.m",
          },
          //           {
          //             id: 9,
          //             heading:
          //               "10. Optimizing URL Handling for SafePay Integration",
          //             explanation: [
          //               `[SafePayHelperClass getSafePaybaseSuccessURLAddressesArray] and [SafePayHelperClass getSafePaybaseFailureURLAddressesArray] are two global arrays that contain success and failure URLs from the server. However, you can create these arrays locally, like this: success_array = [@“success”, @“success/done”, @“complete”]
          // failure_array = [@“failure”, @“failed”, @“not_processed”] The code will run in a loop or use the string contains function to verify if the URL indicates success or failure, and then execute the necessary actions accordingly.`,
          //             ],
          //             linesToHighlight: Array.from(
          //               { length: 2 },
          //               (_, i) => i + 86
          //             ),
          //             tab: "safepay-checkout.m",
          //           },
        ],
      };

    default:
      return {};
  }
}

// ****************** PAYLOADS ****************** //

export var payloads = {
  payments: {
    fixedHeight: true,
    titles: ["payments.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "payments.js",
      code: `
      {
        "data": {
            "token": "C5438LD948188B0GPSH0",
            "client_id": "sec_55ca6960-e2ea-4643-b0cf-7cd65a4d3112",
            "type": "payment:created",
            "endpoint": "https://example.com/listener",
            "notification": {
                "tracker": "track_gfdkjg80-4knkjnlhknjn9-klnk9knn",
                "reference": "514087",
                "intent": "PAYFAST",
                "fee": "4.92",
                "net": "145.08",
                "user": "johndoe@gmail.com",
                "state": "PAID",
                "amount": "150",
                "currency": "PKR",
                "metadata": {
                    "order_id": "XG102312",
                    "source": "shopify"
                }
            },
            "delivery_attempts": 1,
            "resource": "notification",
            "next_attempt_at": "2021-10-01T09:05:33Z",
            "created_at": "2021-10-01T09:05:34Z"
        }
    }
  `,
    },
  },
  refunds: {
    fixedHeight: true,
    titles: ["refunds.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "refunds.js",
      code: `
      {
        "data": {
            "token": "C243APO942228CP0L5GF",
            "client_id": "sec_55ca6960-e2ea-4643-b0cf-7cd65a4d3112",
            "type": "payment:created",
            "endpoint": "https://example.com/listener",
            "notification": {
                "tracker": "track_gfdkjg80-4knkjnlhknjn9-klnk9knn",
                "intent": "PAYFAST",
                "user": "johndoe@gmail.com",
                "state": "PARTIALLY REFUNDED",
                "amount": "25",
                "balance": "125",
                "currency": "PKR",
                "metadata": {
                    "order_id": "XG102312",
                    "source": "shopify"
                }
            },
            "delivery_attempts": 1,
            "resource": "notification",
            "next_attempt_at": "2021-10-01T09:05:33Z",
            "created_at": "2021-10-01T09:05:34Z"
        }
    }
  `,
    },
  },
  errors: {
    fixedHeight: true,
    titles: ["errors.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "errors.js",
      code: `
      {
        "data": {
            "token": "C59KV5CBCV49VMOLCMVG",
            "client_id": "sec_55ca6960-e2ea-4643-b0cf-7cd65a4d3112",
            "type": "error:occurred",
            "endpoint": "https://example.com/listener",
            "notification": {
                "tracker": "track_5b694027-7947-4187-bbaa-8585a07c0cbc",
                "intent": "PAYFAST",
                "message": "55 : Incorrect OTP",
                "metadata": {
                    "order_id": "XG102312",
                    "source": "shopify"
                }
            },
            "delivery_attempts": 1,
            "resource": "notification",
            "next_attempt_at": "2021-10-01T09:05:33Z",
            "created_at": "2021-10-01T09:05:34Z"
        }
    }
  `,
    },
  },
  paymentSucceeded: {
    fixedHeight: true,
    titles: ["paymentSucceeded.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "paymentSucceeded.js",
      code: `
      {
        "token":"evt_64b3218e-f65c-45a9-96b0-fe4e293bb879",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"payment.succeeded",
        "endpoint":"http://example.com",
        "data":{
            "tracker":"track_06ee38cb-981d-4158-819f-7231f28314e4",
            "intent":"CYBERSOURCE",
            "state":"TRACKER_ENDED",
            "net":43525,
            "fee":1475,
            "customer_email":"hzee@gmail.com",
            "amount":45000,
            "currency":"PKR",
            "metadata":{
              "order_id":"AX-09u812312",
              "source":"shopify"
            },
            "charged_at":{
              "seconds":1698754230,
              "nanos":752997627
            }
        },
        "created_at":{
            "seconds":1698754230,
            "nanos":912705711
        }
      }
      `,
    },
  },
  paymentFailed: {
    fixedHeight: true,
    titles: ["paymentFailed.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "paymentFailed.js",
      code: `
      {
        "token":"evt_e7069e13-e4a4-4518-b5ce-ee42e62b6797",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"payment.failed",
        "endpoint":"http://example.com",
        "data":{
            "tracker":"track_138cf593-3d3f-4d54-b9c6-27a510afb780",
            "intent":"CYBERSOURCE",
            "state":"TRACKER_ENROLLED",
            "customer_email":"hzee@gmail.com",
            "metadata":{},
            "category":"PAYMENT_METHOD_ERROR",
            "code":403,
            "message":"The card you have used has been flagged as either stolen or lost. ",
            "failed_at":{
              "seconds":1698754648,
              "nanos":494424793
            }
        },
        "created_at":{
            "seconds":1698754648,
            "nanos":589390626
        }
      }
      `,
    },
  },
  paymentRefunded: {
    fixedHeight: true,
    titles: ["paymentRefunded.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "paymentRefunded.js",
      code: `
      {
        "token":"evt_48431483-2111-4328-8729-309c96c7a0f6",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"payment.refunded",
        "endpoint":"http://example.com",
        "data":{
            "tracker":"track_06ee38cb-981d-4158-819f-7231f28314e4",
            "intent":"CYBERSOURCE",
            "state":"TRACKER_PARTIAL_REFUND",
            "customer_email":"hzee@gmail.com",
            "balance":44000,
            "currency":"PKR",
            "metadata":{
              "order_id":"AX-09u812312",
              "source":"shopify"
            },
            "refund_amount":1000,
            "refunded_at":{
              "seconds":1698758038,
              "nanos":682810001
            }
        },
        "created_at":{
            "seconds":1698758038,
            "nanos":880931084
        }
      }
      `,
    },
  },
  authorizationReversed: {
    fixedHeight: true,
    titles: ["authorizationReversed.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "authorizationReversed.js",
      code: `
      {
        "token":"evt_91ff4cf9-06e5-47ef-a110-2c82dccc8235",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"authorization.reversed",
        "endpoint":"http://example.com",
        "data":{
            "tracker":"track_c3f7bc7e-7ae5-4e8a-b31d-b5bc74fa7e38",
            "intent":"CYBERSOURCE",
            "state":"TRACKER_REVERSED",
            "customer_email":"hzee@gmail.com",
            "metadata":{},
            "reversed_authorization_id":"auth_9dce2ce2-4f65-4aa7-a1ff-8a9a3d6e64b3",
            "reversed_at":{
              "seconds":1698757881,
              "nanos":354475053
            }
        },
        "created_at":{
            "seconds":1698757881,
            "nanos":493894470
        }
      }
      `,
    },
  },
  voidSucceeded: {
    fixedHeight: true,
    titles: ["voidSucceeded.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "voidSucceeded.js",
      code: `
      {
        "token":"evt_846f361c-f3f8-492d-a3e9-f895ea9c91ce",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"void.succeeded",
        "endpoint":"http://example.com",
        "data":{
          "tracker":"track_d927a58c-83cf-4e1a-904c-1b439fa913a4",
          "intent":"CYBERSOURCE",
          "state":"TRACKER_VOIDED",
          "customer_email":"hzee@gmail.com",
          "metadata":{
              "order_id":"AX-09u812312",
              "source":"shopify"
          },
          "void_type":"CAPTURE",
          "voided_charge_id":"cap_d40b58da-cdba-4843-92ac-eb046ed325de",
          "voided_at":{
              "seconds":1698758174,
              "nanos":715064342
          }
        },
        "created_at":{
          "seconds":1698758174,
          "nanos":872747134
        }
      }
      `,
    },
  },
  subscriptionCreated: {
    fixedHeight: true,
    titles: ["subscriptionCreated.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "subscriptionCreated.js",
      code: `
      {
        "token":"evt_071ce9e0-6e95-4761-87a1-1de08be5a0c3",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"subscription.created",
        "endpoint":"http://example.com",
        "data":{
          "id":"sub_73f6b269-4c8e-44d6-b55e-b982ef5f2835",
          "plan_id":"plan_c749c21e-f075-4cc4-8d3d-4e44e6bf3623",
          "customer_email":"hzaidi@getsafepay.com",
          "status":"INCOMPLETE",
          "billing_cycle_anchor":{
              "seconds":1698758572,
              "nanos":667943762
          },
          "amount":100000,
          "currency":"PKR",
          "balance":"0",
          "started_at":{
              "seconds":1698758572,
              "nanos":667943762
          },
          "expires":true,
          "current_period_start_date":{
              "seconds":1698758572,
              "nanos":667943762
          },
          "current_period_end_date":{
              "seconds":1698758631,
              "nanos":667943762
          },
          "number_of_billing_cycles":2,
          "updated_at":{
              "seconds":1698758572,
              "nanos":667943762
          },
          "created_at":{
              "seconds":1698758572,
              "nanos":667943762
          }
        },
        "created_at":{
          "seconds":1698758572,
          "nanos":935293429
        }
      }
      `,
    },
  },
  subscriptionCanceled: {
    fixedHeight: true,
    titles: ["subscriptionCanceled.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "subscriptionCanceled.js",
      code: `
      {
        "token":"evt_3557f8ba-e411-48b7-9d13-c0a355873735",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"subscription.canceled",
        "endpoint":"http://example.com",
        "data":{
            "id":"sub_479236af-b767-473b-acdf-6855db20c023",
            "plan_id":"plan_22933989-a533-44d6-80d2-c1816571f451",
            "customer_email":"hzaidi@getsafepay.com",
            "status":"CANCELED",
            "amount":300000,
            "currency":"PKR",
            "balance":"0",
            "canceled_at":{
              "seconds":1698759228
            },
            "updated_at":{
              "seconds":1698759228
            },
            "created_at":{
              "seconds":1698759224
            }
        },
        "created_at":{
            "seconds":1698759227,
            "nanos":992878718
        }
      }
      `,
    },
  },
  subscriptionEnded: {
    fixedHeight: true,
    titles: ["subscriptionEnded.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "subscriptionEnded.js",
      code: `
      {
        "token":"evt_1ba6f54f-4ed9-4e5a-b7a1-cd45598ec432",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"subscription.ended",
        "endpoint":"http://example.com",
        "data":{
          "id":"sub_73f6b269-4c8e-44d6-b55e-b982ef5f2835",
          "plan_id":"plan_c749c21e-f075-4cc4-8d3d-4e44e6bf3623",
          "customer_email":"hzaidi@getsafepay.com",
          "status":"ENDED",
          "amount":100000,
          "currency":"PKR",
          "balance":"0",
          "updated_at":{
              "seconds":1698758619
          },
          "created_at":{
              "seconds":1698758573
          }
        },
        "created_at":{
          "seconds":1698758645,
          "nanos":20656046
        }
      }
      `,
    },
  },
  subscriptionPaused: {
    fixedHeight: true,
    titles: ["subscriptionPaused.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "subscriptionPaused.js",
      code: `
      {
        "token":"evt_3ebf1fea-b065-4a7d-925c-199245f98114",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"subscription.paused",
        "endpoint":"http://example.com",
        "data":{
            "id":"sub_7e48edb7-daf8-47bf-9679-5b1cf24ff614",
            "plan_id":"plan_22933989-a533-44d6-80d2-c1816571f451",
            "customer_email":"hzaidi@getsafepay.com",
            "status":"PAUSED",
            "billing_cycle_anchor":{
              "seconds":1698760037
            },
            "amount":300000,
            "currency":"PKR",
            "balance":"0",
            "paused_at":{
              "seconds":1698760042
            },
            "updated_at":{
              "seconds":1698760042
            },
            "created_at":{
              "seconds":1698760037
            }
        },
        "created_at":{
            "seconds":1698760042,
            "nanos":438161220
        }
      }
      `,
    },
  },
  subscriptionResumed: {
    fixedHeight: true,
    titles: ["subscriptionResumed.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "subscriptionResumed.js",
      code: `
      {
        "token":"evt_9e0c9f81-8607-4fd7-936c-56fed465ee90",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"subscription.resumed",
        "endpoint":"http://example.com",
        "data":{
            "id":"sub_5726bec7-1d2a-424d-a650-04b963c0e854",
            "plan_id":"plan_c749c21e-f075-4cc4-8d3d-4e44e6bf3623",
            "customer_email":"hzaidi@getsafepay.com",
            "status":"ACTIVE",
            "billing_cycle_anchor":{
              "seconds":1698760196
            },
            "amount":100000,
            "currency":"PKR",
            "balance":"0",
            "resumed_at":{
              "seconds":1698760196
            },
            "updated_at":{
              "seconds":1698760196
            },
            "created_at":{
              "seconds":1698760185
            }
        },
        "created_at":{
            "seconds":1698760196,
            "nanos":518876958
        }
      }
      `,
    },
  },
  subscriptionPaymentSucceeded: {
    fixedHeight: true,
    titles: ["subscriptionPaymentSucceeded.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "subscriptionPaymentSucceeded.js",
      code: `
      {
        "token":"evt_ea9ab1a2-4a3b-478a-ac82-cb986b60242e",
        "version":"2.0.0",
        "merchant_api_key":"sec_edeade52-46aa-483b-b87d-009d3ce41554",
        "type":"subscription.payment.succeeded",
        "endpoint":"http://example.com",
        "data":{
           "id":"sub_73f6b269-4c8e-44d6-b55e-b982ef5f2835",
           "plan_id":"plan_c749c21e-f075-4cc4-8d3d-4e44e6bf3623",
           "customer_email":"hzaidi@getsafepay.com",
           "status":"ACTIVE",
           "billing_cycle_anchor":{
              "seconds":1698758633
           },
           "amount":100000,
           "currency":"PKR",
           "balance":"0",
           "current_period_start_date":{
              "seconds":1698758633
           },
           "current_period_end_date":{
              "seconds":1698758692
           },
           "last_paid_date":{
              "seconds":1698758586,
              "nanos":667158380
           },
           "current_billing_cycle":1,
           "updated_at":{
              "seconds":1698758573
           },
           "created_at":{
              "seconds":1698758573
           },
           "transaction_id":"txn_8b29b706-168d-4b38-991c-ac77620a29b7",
           "transaction_status":"COMPLETE"
        },
        "created_at":{
           "seconds":1698758586,
           "nanos":858671671
        }
      }
      `,
    },
  },
  subscriptionPaymentFailed: {
    fixedHeight: true,
    titles: ["subscriptionPaymentFailed.js"],
    linesToHighlight: [],
    activeTab: {
      language: "json",
      title: "subscriptionPaymentFailed.js",
      code: `
      {
        "token": "evt_3f8ee089-c083-4475-af48-036b38140053",
        "version": "2.0.0",
        "merchant_api_key": "sec_d2ec84f8-eae4-4e50-80ba-dad01c7bab83",
        "type": "subscription.payment.failed",
        "endpoint": "https://example.com",
        "data": {
          "id": "sub_b55072cf-2556-4c17-82b4-dae075960df8",
          "plan_id": "plan_8ae8e989-08fd-47dd-9a95-04056c83b6fa",
          "customer_email": "hzaidi@getsafepay.com",
          "status": "UNPAID",
          "billing_cycle_anchor": {
            "seconds": 1710824626
          },
          "amount": 405100,
          "currency": "PKR",
          "balance": "0",
          "updated_at": {
            "seconds": 1710824626
          },
          "created_at": {
            "seconds": 1710824626
          },
          "transaction_id": "txn_b54bb42c-bc55-481b-b1d9-fd945f44f8c3",
          "transaction_status": "FAILED",
          "transaction_error_code": "NONE_TRANSACTION_FAILURE_CODE",
          "transaction_error_message": "unrecognized or unsupported action kind"
        },
        "created_at": {
          "seconds": 1710825617,
          "nanos": 655468013
        }
      }
      `,
    },
  },

  configuration: {
    fixedHeight: true,
    titles: ["config.yml"],
    linesToHighlight: [],
    activeTab: {
      language: "yml",
      title: "config.yml",
      code: `
      title: Safepay Configuration
      sidebar:
        Introduction: /
        Why Safepay: /why-safepay
  `,
    },
  },
};

export var sigVerification = [
  {
    name: "JavaScript",
    language: "javascript",
    codeBlockOnly: true,
    fixedHeight: true,
    titles: ["verify.js"],
    linesToHighlight: [],
    activeTab: {
      language: "javascript",
      title: "verify.js",
      code: `
      const crypto = require(‘crypto’);
var payload = {
    token: “C5A5APSBCV41R2QF2MHG”,
    client_id: “sec_55ca6960 - e2ea - 4643 - b0cf - 7 cd65a4d3112”,
    type: “payment: created”,
    endpoint: “http: //127.0.0.1:9000”,
        notification: {
            tracker: “tracker_c5a5apsbcv41om3fg0u0 ",
            reference: “514087”,
            intent: “PAYFAST”,
            fee: “4.92”,
            net: “145.08 ",
            user: “johndoe @gmail.com”,
            state: “PAID”,
            amount: “150”,
            currency: “PKR”,
            metadata: {
                order_id: “XG102312”,
                source: “shopify”
            }
        },
    delivery_attempts: 1,
    resource: “notification”,
    next_attempt_at: “2021 - 10 - 01 T09: 05: 33 Z”,
    created_at: “2021 - 09 - 29 T12: 00: 40 Z”
};
const data = Buffer.from(JSON.stringify(payload));
const secret = ‘86 f63866a6e9b27f8f48a2d0a2946171b03639ea2e5000213e641da781a87d6e’;
const hash = crypto.createHmac(‘sha512’, secret)
    .update(data)
    .digest(‘hex’);
console.log(hash);
  `,
    },
  },
  {
    name: "PHP",
    language: "php",
    codeBlockOnly: true,
    fixedHeight: true,
    titles: ["verify.php"],
    linesToHighlight: [],
    activeTab: {
      language: "php",
      title: "verify.js",
      code: `
      /Laravel implementation for Safepay webhooks
function safepay_notification(Request $request) {
    $request_all = $request->all();
    $request_data = $request_all[‘data’];
    $data[‘payment’]  = ‘SafePay’;
    $data[‘body’]     = json_encode($request_data);
    DB::table(‘payment_logs’)->insert($data);
    $event_type = $request_data[‘type’]; //payment:created, error:occurred
    $x_sfpy_signature = $request->header(‘x-sfpy-signature’);
    $web_hook_share_secret = config(‘safepay.webhook_share_secret_key’);
    $signature_2 = hash_hmac(‘sha512’,json_encode($request_data,JSON_UNESCAPED_SLASHES), $web_hook_share_secret);
    if ( $signature_2  !== $x_sfpy_signature ) {
          return  $this->response([
            ‘status’ => false,
            ‘dataSet’ => [],
                ],
            self::HTTP_OK // HTTP_UNAUTHORIZED
      );
    }
    $order_metadata = $request_data[‘notification’][‘metadata’][‘order_id’]??‘’;
    if( empty($order_metadata) ) {
          return  $this->response([
            ‘status’ => false,
            ‘dataSet’ => [],
                ],
            self::HTTP_OK // HTTP_UNAUTHORIZED
      );
    }
    if( $event_type == ‘payment:created’) {
      $safepay_reference_code = $request_data[‘notification’][‘reference’];
    }else { //error:occured
      //Add Note.
    }
    return  $this->response([
      ‘status’ => TRUE,
      ‘dataSet’ => [],
            ],
      self::HTTP_OK
    );
}
  `,
    },
  },
  {
    name: "C#",
    language: "csharp",
    codeBlockOnly: true,
    fixedHeight: true,
    titles: ["verify.cs"],
    linesToHighlight: [],
    activeTab: {
      language: "csharp",
      title: "verify.cs",
      code: `
      using System;
using System.Linq;
using System.Net.Http;
using System.Security.Cryptography;
using System.Text;

public static class Verification
{
  /// <summary>
  /// Verifies the Safepay Webhook signature against its content.
  /// </summary>
  /// 
  /// <param name="message"> The HTTP request message received in the Webhook payload. </param>
  /// <param name="webhookSecret"> Your Safepay Webhook secret key. </param>
  /// 
  /// <returns></returns>
  public static bool VerifyWebhook(HttpRequestMessage message, string webhookSecret)
  {
    // Get the header values
    var values = message.Headers.GetValues("X-SFPY-SIGNATURE");
    if (values == null)
    {
      return false;
    }

    // Get the signature value
    string signature = values.FirstOrDefault();

    // Reconstruct the signature using the secret key
    string content = message.Content.ReadAsStringAsync().Result;
    string hmac = GetHMAC(content, webhookSecret);

    // Validate the signature
    return string.Equals(signature, hmac, System.StringComparison.OrdinalIgnoreCase);
  }

  private static string GetHMAC(string text, string key)
  {
    using var hmacsha512 = new HMACSHA512(Encoding.UTF8.GetBytes(key));
    var hash = hmacsha512.ComputeHash(Encoding.UTF8.GetBytes(text));
    return ToHexString(hash);
  }

  private static string ToHexString(byte[] input)
  {
    var hexString = BitConverter.ToString(input);
    return hexString.Replace("-", "");
  }
}
      `,
    },
  },
  {
    name: "Python",
    language: "python",
    codeBlockOnly: true,
    fixedHeight: true,
    titles: ["verify.py"],
    linesToHighlight: [],
    activeTab: {
      language: "python",
      title: "verify.py",
      code: `
      import json
from hashlib import sha256, sha512
import hmac
import six

class Verify:
  # You may find the webhook secret from the merchant dashboard
  def __init__(self, webhookSecret):
    self.webhookSecret = webhookSecret

  def webhook(self, header, body):
    signature = (header['x-sfpy-signature'])
    secret = self.webhookSecret
    payload = json.dumps(
        body['data'], separators=(',', ':'))

    mac = hmac.new(secret.encode('utf-8'),
                    msg=payload.encode('utf-8'),
                    digestmod=sha512)
    comptuted_sig = mac.hexdigest()
    comparison_res = secure_compare(comptuted_sig, signature)
    
    return comparison_res

  def secure_compare(a, b):
    """
    Return 'a == b'.  This function uses an approach designed to prevent
    timing analysis, making it appropriate for cryptography.
    a and b must both be of the same type: either str (ASCII only),
    or any bytes-like object.
    """

    def utf8(value):
        if six.PY2 and isinstance(value, six.text_type):
            return value.encode('utf-8')
        else:
            return value

    return hmac.compare_digest(utf8(a), utf8(b))

      `,
    },
  },
];
