import HowSubsWork from "../../../../src/assets/subscriptions/how-subs-work.png";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Text } from "@chakra-ui/react";
import * as React from 'react';
export default {
  HowSubsWork,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  React
};