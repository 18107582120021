import IntegrationSteps from "./IntegrationSteps";
import StepsExplanation from "./StepsExplanation";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { getCodeBlockDetails } from "../utils/codeblocks";

export default function CheckoutButton({ language }) {
  const [params, setParams] = useState({
    amount: 1000,
    currency: "PKR",
  });

  const [currentCodeblock, setCurrentCodeblock] = useState(
    getCodeBlockDetails(language, params)
  );

  const codeBlocks = useMemo(() => {
    return currentCodeblock.codeBlocks;
  }, [currentCodeblock]);

  const titles = useMemo(() => {
    return codeBlocks.map((c) => c.title);
  }, [codeBlocks]);

  const steps = useMemo(() => {
    return currentCodeblock.steps;
  }, [currentCodeblock]);

  const preview = useMemo(() => {
    return currentCodeblock.preview;
  }, [currentCodeblock]);

  const [activeTab, setActiveTab] = useState(codeBlocks[0]);
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [linesToHighlight, setLinesToHighlight] = useState([]);

  const changeActiveStep = useCallback(
    (step) => {
      const currentStep = steps.find(
        (element) => element.id === step.id
      );
      if (currentStep && !currentStep.doesNotGetSelected) {
        setActiveStep(currentStep);
        changeActiveTab(currentStep.tab);
      }
    },
    [activeStep]
  );

  useEffect(() => {
    changeActiveTab(activeTab.title);
  }, [activeTab, codeBlocks]);

  const changeActiveTab = useCallback(
    (activeTabTitle) => {
      const currentTab = codeBlocks.find(
        (element) => element.title === activeTabTitle
      );
      setActiveTab(currentTab);
    },
    [language, codeBlocks, activeTab]
  );

  const handleNumberFieldOnChange = useCallback(
    (event) => {
      setParams({
        currency: params.currency,
        amount: event.target.value,
      });
    },
    [params]
  );

  const handleSelectFieldOnChange = useCallback(
    (event) => {
      setParams({
        amount: params.amount,
        currency: event.target.value,
      });
    },
    [params]
  );

  useEffect(() => {
    setCurrentCodeblock(getCodeBlockDetails(language, params));
  }, [params, language]);

  useEffect(() => {
    if (activeStep) {
      if (
        activeStep.linesToHighlight &&
        activeTab.title === activeStep.tab
      ) {
        setLinesToHighlight(activeStep.linesToHighlight);
      } else {
        setLinesToHighlight([]);
      }
    }
  }, [activeStep, activeTab]);

  return (
    <Box>
      <Text mb={3}>
        A set of components allowing easy integration of Safepay
        Button and Safepay Checkout into your website, powered by zoid
      </Text>
      <Flex>
        <StepsExplanation
          steps={steps}
          changeActiveStep={changeActiveStep}
          activeStep={activeStep}
          handleNumberFieldOnChange={handleNumberFieldOnChange}
          handleSelectFieldOnChange={handleSelectFieldOnChange}
          params={params}
        />
        <IntegrationSteps
          titles={titles}
          activeTab={activeTab}
          changeActiveTab={changeActiveTab}
          linesToHighlight={linesToHighlight}
          preview={preview}
        />
      </Flex>
    </Box>
  );
}

CheckoutButton.propTypes = {
  language: PropTypes.string.isRequired,
};
