import React from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import PropTypes from "prop-types";

export default function MultiCheckout({
  languages,
  changeLanguage,
  currentLanguage,
}) {
  return (
    <ButtonGroup mb={5}>
      {languages &&
        languages.map(({ name, language }) => {
          return (
            <Button
              onClick={() => {
                changeLanguage(language);
              }}
              mr={3}
              colorScheme={currentLanguage === language && "blue"}
              _hover={{ backgroundColor: "bgCode" }}
              variant="outline"
              borderWidth={currentLanguage === language && "2px"}
              borderColor={currentLanguage !== language && "codeBlockBorder"}
              p={3}
              h={9}
            >
              {name}
            </Button>
          );
        })}
    </ButtonGroup>
  );
}

MultiCheckout.propTypes = {
  languages: PropTypes.array.isRequired,
};
