import ReclaimationProcess from "../../../../src/assets/subscriptions/reclaimation-process.png";
import TransactionBehavior from "../../../../src/assets/subscriptions/transaction-behavior.png";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, Text } from "@chakra-ui/react";
import * as React from 'react';
export default {
  ReclaimationProcess,
  TransactionBehavior,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Text,
  React
};